.page-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid black; /* for demo */
    background: #fff;
    display: none;
}

.page-footer,
.page-footer-space {
    height: 50px;
}

.page {
    page-break-after: avoid;
}

.h-5-rem {
    height: 5rem;
}

.p-not-print-x {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.p-not-print-y {
    padding-top: 0.5rem;
    padding-top: 0.5rem;
}

@page {
    margin: 7mm 10mm 0 10mm;
}

@media print {
    thead {
        display: table-header-group;
    }
    tfoot {
        display: table-footer-group;
    }

    .page-footer {
        display: initial;
    }

    button {
        display: none;
    }

    body {
        margin: 0;
    }

    .p-not-print-x {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .hide-on-print {
        display: none !important;
    }

    .al-sidebar,
    ba-page-top,
    ba-notification-favorite,
    .page-top,
    .sidebar-notification {
        display: none !important;
    }

    .al-main,
    .al-content {
        margin-left: 0 !important;
        padding: 0 !important;
    }

    .page-break {
        page-break-after: always;
    }

    .page-header {
        width: 100%;
        max-height: 355px;
        background: #fff;
    }
}
