﻿@import "./conf/conf";

$default-text-size: 14px;

@media only screen and (max-width: 1400px) and (min-width: 1200px) {
    .top-page-dropdown {
        top: 15px !important;
    }
    
    .component-result {
        margin-top: 0 !important;
    }

    .al-sidebar,
    .user-Profile,
    .brand-icon {
        width: $sidebar-width-1200 !important;

        #user-image {
            padding: 15px 0px 15px 25px !important;

            img {
                width: 70px !important;
            }
        }
    }

    .page-top,
    .brand-icon,
    .multiLingual {
        height: $top-height-1200 !important;
    }

    .al-sidebar {
        top: $top-height-1200 !important;
    }

    .multiLingual {
        button {
            padding: 22px 15px 22px 23px !important;
        }
    }

    .al-main {
        padding-top: $top-height-1200 !important;
        margin-right: auto;
        margin-left: $sidebar-width-1200 !important;
    }

    .menu-collapsed {
        #patient-picture {
            left: $main-collapsed-1200;
        }

        .al-sidebar {
            width: $main-collapsed !important;
        }

        .al-main {
            margin-right: auto !important;
            margin-left: $main-collapsed !important;
        }

        .business-card {
            width: 270px !important;
        }
    }

    #sidebar-icon {
        top: 17px !important;
        left: 286px !important;

        span.hamburger {
            top: 9px !important;
            left: 286px !important;

            span:before,
            span:after {
                width: 18px !important;
            }
        }

        span.line {
            width: 18px !important;
        }

        img {
            width: 30px !important;
        }
    }

    #search-item {
        top: 20px !important;
        left: 330px !important;
    }

    .al-sidebar-list-item {
        padding: 15px 0 !important;
    }

    a.al-sidebar-list-link {
    }

    .header-item {
        top: 15px !important;

        i {
        }
    }

    .business-card {
        width: 285px !important;

        .title {
            padding: 20px 0 0 0 !important;
        }

        .finer-print {
        }
    }

    .rtl {
        .al-main {
            margin-right: $sidebar-width-1200 !important;
        }

        .menu-collapsed .al-main {
            margin-left: auto !important;
            margin-right: $main-collapsed !important;
        }

        #user-image {
            padding: 15px 25px 15px 0 !important;
        }

        #sidebar-icon {
            top: 17px !important;
            left: auto !important;
            right: 285px !important;

            span.hamburger {
                top: 9px !important;
                right: 286px !important;
                left: auto;

                span:before,
                span:after {
                    width: 18px !important;
                }
            }
        }

        #search-item {
            left: auto !important;
            right: 330px !important;
        }

        #sidebar-icon .hamburger {
            left: auto !important;
            right: 331px;
        }

        .business-card .title {
            padding: 20px 25px 0 0 !important;
        }
    }
}
