@import "./conf/_variables.scss";
@import "./_rtl.scss";
@import "./@size1400";
@import "./@size1200";
@import "./_loading.scss";
@import "./_tabView.scss";
@import "./_form.scss";
@import "./_fonts.scss";

html,
body {
  background-color: #eef0f5;
  font-size: $default-font-size;
  font-family: $default-font-family;
  color: #ababab;
}

a {
  cursor: pointer;
}

label {
  margin-bottom: 0;
}

.windows body,
.windows p {
  letter-spacing: normal;
}

.upper-case {
  text-transform: uppercase;
}

span.logo-title {
  color: #fff;
}

.text-right {
  text-align: right;
}

.col-center {
  text-align: center !important;
}

.ui-dropdown {
  width: 100% !important;
  min-width: 0 !important;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.3em;
  vertical-align: middle;
  content: "";
  border-top: 0.3em solid;
  border-right: 0em solid transparent;
  border-left: 0em solid transparent;
}

.search-result {
  position: fixed;
  left: 50px;
  z-index: 1000;
  top: 135px;
  width: 300px;
  padding: 20px 5px 10px 15px;

  .row {
    margin: 0 -5px;
    cursor: pointer;
  }

  .col-sm-12 {
    border-bottom: 0 !important;
    padding: 0 10px !important;
  }
}

small {
  font-size: 90%;
}

.without-padding {
  padding: 0 !important;
}

.shortcut-notification {
  margin: 0 0 0 0;
  width: 60px;
  position: fixed;
  top: 100px;
  overflow-y: auto;
  min-height: 0;
  z-index: 1001 !important;
  overflow-x: hidden;
  margin-left: 0px;
  border-right: 0;
  background: transparent;
  z-index: 1002;
  left: 0px;
  overflow: hidden;

  &.home-notification {
    top: 280px;
  }

  ul {
    padding: 10px 1px;
    margin: 0 0 0px;
    border-bottom: 0;
  }

  li {
    display: block;
    width: 100%;
    height: 35px;
    border-radius: 0;
    text-align: center;
    line-height: 35px;
    background-color: transparent;
    margin-right: 0;
    color: #1976d2;
    cursor: pointer;
    transition: background-color;
    transition-duration: 250ms;
    margin: 0;
    text-align: center;

    &:hover {
      color: gray;
      background-color: #fff;
    }

    a {
      color: #1976d2;

      &:hover {
        color: gray;
      }
    }
  }
}

.welcome-card {
  width: 700px;
}

.menu-collapsed {
  .welcome-item {
    padding: 20px 0;
  }

  .menu-collapsed-p-0 {
    .ui-tabview-nav {
      li {
        padding: 0px 5% !important;
      }
    }
  }

  .row-content {
    padding: 5px 0px 0 30px;
  }

  .user-Profile {
    display: none !important;
  }

  .welcome-card {
    width: 865px;
  }

  #patient-picture {
    left: $main-collapsed;
  }

  .chart-details {
    &.active {
      right: auto !important;
      left: -66px;
    }
  }

  #admission-charts-details.active {
    width: calc(100% - 60px);
  }

  .arow-item {
    display: none !important;
  }

  #menus {
    .collapsed {
      display: none;
    }
  }

  .menu-panel-title {
    a i {
      margin-right: 15px !important;
    }
  }
}

.row-content {
  padding: 5px 0px 0 30px;
}

.row-content {
  padding: 5px 0px 0 100px;
}

.shortcut-notification-modal {
  .modal-body {
    .row {
      padding: 0px 25px;
    }
  }

  .modal-dialog {
    margin: 0 0;
    height: 100%;
    width: 20%;
  }

  .modal-header {
    padding: 15px 0 0 0;

    .close {
      &:focus {
        outline: 0;
      }
    }
  }

  .modal-content {
    border-radius: 0;
    height: 100%;
  }

  .form-control {
    border-bottom: 1px solid lightgray;
    border-radius: 0;

    &:focus {
      border-color: transparent;
      border-bottom: 1px solid #4791cd;
    }
  }

  .ui-inputtext {
    width: 100%;
    border-bottom: 1px solid lightgray;
    border-radius: 0px;
    padding: 10px 0px 7px 15px !important;

    &:focus {
      -moz-box-shadow: 0px 0px 0px #1f89ce;
      -webkit-box-shadow: 0px 0px 0px #1f89ce;
      box-shadow: 0px 0px 0px #1f89ce;
      border-bottom: 1px solid #4791cd;
    }
  }

  input.undefined.ui-inputtext.ui-corner-all.ui-state-default.ui-widget {
    margin: 5px 0 0 0 !important;
  }

  .ui-datepicker.ui-widget {
    padding: 0;
    width: 100%;
  }

  .ui-calendar {
    width: 100%;
  }
}

.ui-calendar {
  width: 100%;
  margin: 10px 0 0 0 !important;
}

.ui-inputtext {
  width: 100% !important;
}

/* accordion */
.modal.in .modal-dialog {
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0);
}

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5 !important;
}

.modal-footer {
  border-top: 1px solid #e5e5e5 !important;
}

.modal-title {
  margin: 0;
  line-height: 1.42857143;
  width: 100%;
  padding: 0;
  font-size: $default-font-size;
}

.modal-body {
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0);
  will-change: box-shadow;
  display: block;
  position: relative;
  padding: 10px 15px;
  border-radius: 2px;
}

/* accordion */

.btn-icon {
  border-radius: 50%;
  width: 20px;
  line-height: 20px;
  height: 20px;
  padding: 0;
  text-align: center;
  color: black;
  background-color: transparent;
}

/* button */
/* Form Layout */
.form-group-default {
  background-color: transparent;
  position: relative;
  padding-top: 7px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 4px;

  .ui-autocomplete {
    width: 100% !important;
  }
}

.form-group.row.form-group-default .form-control {
  height: 35px;
  margin-top: 0;
}

.form-group {
  margin-bottom: 0;
}

.form-group-default .form-control {
  border: none;
  height: 25px;
  min-height: 25px;
  padding: 0;
  margin-top: -4px;
  background: none;
}

.form-horizontal {
  .form-group-default {
    background: inherit;
    border: none !important;
    overflow: inherit;
  }

  .form-group.row.form-group-default {
    padding-top: 0px;

    label {
      border: none !important;
    }

    .switch-small {
      margin-top: 5px;
    }

    input {
      padding: 0px 10px;
      box-shadow: 0px 0px 5px 0px #d4d4d4;
      background-color: white;
      border-radius: 0px;
      height: 35px;
      border: 1px solid #d4d4d4;
    }
  }

  .form-group.row.form-group-default .col-lg-3,
  .form-group.row.form-group-default .col-md-6,
  .form-group.row.form-group-default .col-sm-12 {
    padding: 5px 10px 5px 10px;
  }

  .form-control[disabled],
  fieldset[disabled] .form-control {
    background-color: #e1e1e1 !important;
  }

  p-calendar {
    box-shadow: 0px 0px 5px 0px #e1e1e1;
  }

  .ui-datepicker {
    position: absolute !important;
  }

  .ui-calendar {
    margin: 0 !important;
  }

  .ui-dropdown {
    width: 100% !important;
  }

  .ui-dropdown-panel {
    position: absolute !important;
  }

  .ui-dropdown-label {
    margin-bottom: 0px !important;
    padding: 0px 10px;
    height: 35px;
  }

  .ui-dropdown.ui-state-focus,
  .ui-multiselect.ui-state-focus {
    box-shadow: none !important;
  }

  .ui-dropdown .ui-dropdown-trigger .fa {
    margin-top: 8px;
  }

  .ui-dropdown .ui-state-default {
    color: $primary-color;
  }

  .ui-dropdown.ui-widget.ui-state-default.ui-corner-all.ui-helper-clearfix {
    border-radius: 0px;
    box-shadow: 0px 0px 5px 0px #d4d4d4;
  }

  .ui-dropdown-label.ui-placeholder {
    color: #959595 !important;
  }

  .ui-dropdown.ui-state-disabled,
  .ui-dropdown.ui-state-disabled .ui-dropdown-label,
  .ui-dropdown.ui-state-disabled .ui-corner-right {
    background-color: #e1e1e1 !important;
    opacity: 1;
    cursor: not-allowed !important;
    color: #8e8e8e !important;
  }

  .ui-multiselect-panel {
    position: absolute !important;
  }

  .ui-multiselect-label {
    margin-bottom: 0px !important;
    padding: 0px 10px;
    height: 35px;
  }

  .ui-multiselect.ui-state-focus,
  .ui-multiselect.ui-state-focus {
    box-shadow: none !important;
  }

  .ui-multiselect .ui-multiselect-trigger .fa {
    margin-top: 8px;
  }

  .ui-multiselect .ui-state-default {
    color: $primary-color;
  }

  .ui-multiselect.ui-widget.ui-state-default.ui-corner-all {
    width: 100%;
  }

  .ui-multiselect.ui-widget.ui-state-default.ui-corner-all.ui-helper-clearfix {
    border-radius: 0px;
    box-shadow: 0px 0px 5px 0px #e1e1e1;
  }

  .ui-multiselect-label.ui-placeholder {
    color: #959595 !important;
  }

  .ui-multiselect.ui-state-disabled,
  .ui-multiselect.ui-state-disabled .ui-multiselect-label,
  .ui-multiselect.ui-state-disabled .ui-corner-right {
    background-color: #e1e1e1 !important;
    opacity: 1;
    cursor: not-allowed !important;
    color: #8e8e8e !important;
  }

  .ui-corner-all {
    border-radius: 0px !important;
  }

  textarea {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-text-fill-color: #ababab !important;
    background-color: #ffffff !important;
    background-image: none !important;
    color: #ababab !important;
  }

  textarea.ui-inputtext.ui-corner-all.ui-state-default.ui-widget {
    box-shadow: 0px 0px 5px 0px #e1e1e1;
  }
}

.has-error {
  input:not([type="checkbox"]):not([type="radio"]) {
    border: 0 !important;
    border-bottom: 1px solid #e52222 !important;
    border-radius: 0;
  }

  p-calendar .ui-inputtext {
    border: 0 !important;
    border-bottom: 1px solid #e52222 !important;
  }

  p-dropdown .ui-dropdown.ui-widget.ui-state-default.ui-corner-all.ui-helper-clearfix {
    border: 0 !important;
    border-bottom: 1px solid #e52222 !important;
  }
}

.ui-tooltip-text.ui-shadow.ui-corner-all {
  background-color: #4b4b4b !important;
}

form {
  display: block;
  margin-top: 0em;
}

.m-t-10 {
  margin-top: 10px;
}

p {
  display: block;
  letter-spacing: 0.01em;
  line-height: 22px;
  margin: 0px 0px 10px 0px;
  font-style: normal;
  white-space: normal;
}

.input-group-addon {
  background: #f0f0f0;
  border: 1px solid rgba(0, 0, 0, 0.07);
  color: rgba(98, 98, 98, 0.47);
  padding: 6px 9px;
  display: table-cell;
  border-radius: 3px;
  transition:
    border 0.2s linear 0s,
    box-shadow 0.2s linear 0s,
    color 0.2s linear 0s,
    box-shadow 0.2s linear 0s,
    background 0.2s linear 0s;
}

.form-control {
  background-color: #ffffff;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.07);
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  height: 35px;
  padding: 9px 12px;
  line-height: normal;
  vertical-align: middle;
  min-height: 35px;
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: background 0.2s linear 0s;
  transition: background 0.2s linear 0s;
}

.input-right {
  float: right;
  direction: rtl;
}

.input-group-addon:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.07);
  border-left: 0;
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
}

.windows h1,
.windows h2,
.windows h3,
.windows h4,
.windows h5 {
  letter-spacing: normal;
}

.windows h3 {
  line-height: 33px;
}

.radio label,
.checkbox label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px !important;
  margin-right: 15px;
}

input[type="radio"],
input[type="checkbox"] {
  margin-top: 1px 0 0;
  line-height: normal;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.checkbox label:before {
  top: 1.4px;
  border-radius: 3px;
  transition:
    border 0.2s linear 0s,
    color 0.2s linear 0s;
}

.checkbox.check-success input[type="checkbox"]:checked + label:before {
  border-color: #10cfbd;
}

.checkbox input[type="checkbox"]:checked + label:before {
  border-width: 8.5px;
}

.checkbox.check-success input[type="checkbox"]:checked + label::after,
.checkbox.check-primary input[type="checkbox"]:checked + label::after,
.checkbox.check-complete input[type="checkbox"]:checked + label::after,
.checkbox.check-warning input[type="checkbox"]:checked + label::after,
.checkbox.check-danger input[type="checkbox"]:checked + label::after,
.checkbox.check-info input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox input[type="checkbox"]:checked + label::after {
  content: "\F00C";
  color: #fff;
}

.checkbox label:after {
  border-radius: 3px;
}

.checkbox label::after {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 3.2px;
  top: 0px;
  transition:
    border 0.2s linear 0s,
    color 0.2s linear 0s;
}

.radio label:before,
.checkbox label:before {
  content: "";
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-right: 10px;
  position: absolute;
  left: 0px;
  background-color: #ffffff;
  border: 1px solid #d0d0d0;
}

.checkbox input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}

.checkbox label {
  transition:
    border 0.2s linear 0s,
    color 0.2s linear 0s;
  white-space: nowrap;
}

.radio label,
.checkbox label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px !important;
  margin-right: 15px;
}

textarea.form-control {
  min-height: 96px !important;
}

textarea.ui-inputtext.ui-corner-all.ui-state-default.ui-widget {
  border: 1px solid #d6d6d6;
}

/* Form Layout */
/* Primeng */
.ui-widget-header .ui-state-default,
.ui-widget-content .ui-state-default,
.ui-state-default {
  border: 0px solid #d6d6d6;
}

.ui-widget-content {
  border: 0px;
  //   font-size: $default-font-size - 2 !important;
}

/*.ui-datepicker {
    position: static;
}*/
.ui-calendar button {
  height: 24px !important;
}

.ui-dropdown-panel .ui-dropdown-filter-container {
  width: 100%;
}

.ui-dropdown:not(.ui-state-disabled):hover {
  background: #ffffff !important;
}

/*.ui-dropdown-panel {
    position: static !important;
}*/
.ui-dropdown-panel .ui-dropdown-item:not(.ui-state-highlight):hover {
  border-color: #ffffff !important;
}

/* Tables */
.ui-datatable .ui-datatable-thead > tr > th,
.ui-datatable .ui-datatable-tfoot > tr > td {
  background: #f6f6f6;
}

.ui-datatable .ui-datatable-thead > tr > th,
.ui-datatable .ui-datatable-tfoot > tr > td,
.ui-datatable .ui-datatable-data > tr > td {
  border: 1px solid #ddd;
}

.ui-datatable .ui-paginator {
  padding: 0.325em;
  border-top: 0 none;

  a.ui-paginator-first.ui-paginator-element.ui-state-default.ui-corner-all {
    border: 0;
    background: transparent;
  }
}

.ui-paginator .ui-paginator-page.ui-state-active {
  background: #2399e5;
  color: #ffffff;
  border-color: #2399e5;
}

.ui-datatable {
  .ui-paginator .ui-paginator-page {
    width: 3em !important;
  }
}

.ui-datatable th.ui-sortable-column:not(.ui-state-active):hover {
  background: #fafafb;
  border-color: #d9d9d9;
}

.ui-datatable-header.ui-widget-header {
  color: #586069;
  background: #dddddd !important;
  border: 1px solid #d5d8dc;
  border-radius: 3px 3px 0px 0px;
}

.ui-widget-header {
  background: linear-gradient(to bottom, transparent 0, transparent 0);
}

.ui-lightbox-caption.ui-widget-header {
  background: #fff;
}

.ui-paginator-bottom.ui-paginator.ui-widget.ui-widget-header.ui-unselectable-text {
  color: #586069;
  background-color: #ffffff;
  border: 1px solid #c8e1ff;
  border-top: 0px !important;
}

.ui-datatable th.ui-state-active {
  color: black;
}

.ui-datatable tbody > tr.ui-widget-content.ui-datatable-odd {
  background-color: #ffffff;

  &:hover {
    background-color: #e9e9e9;
  }
}

.ui-datatable tbody > tr.ui-widget-content {
  &:hover {
    background-color: #e9e9e9;
  }
}

.ui-dropdown-trigger.ui-state-default.ui-corner-right {
  border: 0;
  border: 0 !important;

  &:hover {
    background-color: #ddd;
  }
}

.ui-dropdown.ui-widget.ui-state-default.ui-corner-all.ui-helper-clearfix {
  border: 1px solid #d4d4d4;
}

.ui-datatable tfoot td.ui-state-default {
  background: #f1f8ff;
  border-color: #d9d9d9;
}

.col-button {
  width: 10%;
  text-align: center;
}

.ui-slider .ui-slider-handle {
  $sliderImage: $images-system + "slider_handles.png";
  background: url($sliderImage) 0 0 no-repeat !important;
}

.ui-datatable tbody > tr.ui-widget-content.ui-state-highlight {
  background-color: #186ba0 !important;
  color: #ffffff !important;
}

/*picture Uploader*/
.picture-group {
  border: 1px dashed #b8b8b8;
  width: 202px;
  height: 202px;
  position: relative;
  cursor: pointer;

  .picture-wrapper {
    width: 200px;
    height: 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  i {
    display: none;
    position: absolute;
    background: $default;
    cursor: pointer;
    color: $primary;
    top: -11px;
    right: -11px;
    height: 26px;
    border-radius: 50%;

    &:before {
      line-height: 26px;
    }

    &:hover {
      color: $danger;
    }
  }

  a.change-picture {
    display: none;
    width: 202px;
    background: rgba(0, 0, 0, 0.7);
    transition: all 200ms ease-in-out;
    color: #fff;
    text-decoration: none;
    position: absolute;
    bottom: -1px;
    left: -1px;
    line-height: 32px;
    text-align: center;
  }

  &:hover {
    i {
      display: block;
    }

    .change-picture {
      display: block;
    }
  }

  .loading {
    width: 100%;
    height: 100%;
    left: 0;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
  }

  .spinner {
    width: 60px;
    height: 60px;
    position: relative;
  }

  .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }

  .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }

    50% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }

    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
}

/*picture Uploader*/
/* Panel Header */
.form-group.row.form-group-default.row-header {
  background: #eeeeee;
}

.row-header {
  .ui-panel-content-wrapper {
    background: #ffffff;
  }

  .ui-panel .ui-panel-content {
    border: 0;
    background: none;
    padding: 1em 0.75em;
  }

  .ui-panel-titlebar.ui-widget-header.ui-helper-clearfix.ui-corner-all {
    background: #ffffff;
  }
}

.second-row-header {
  .form-horizontal label {
    line-height: 14px;
  }
}

.row-image-header {
  width: 100%;
  height: auto;
  border: 1px solid #ddd;
  padding: 5px;
}

/* Panel Header */
/* checkbox */
.ui-chkbox .ui-chkbox-box {
  border: 1px solid #d6d6d6;
  height: 1.225em;
}

/* checkbox */
/* tree */

// .ui-tree.ui-widget.ui-widget-content.ui-corner-all {
//     width: 100%;
// }

// .ui-tree .ui-treenode-label {
//     display: inline-block;
//     padding: 0 .25em;
//     width: 85%;
//     vertical-align: middle;
// }

// .ui-treenode-content {
//     margin: 5px 0px;
//     overflow: hidden;
//     outline: none;

//     &:hover{
//         background-color: rgba(0, 0, 0, 0.075);
//     }
// }

label.tree-label {
  padding-top: 5px;
  white-space: initial;
}

.tree-row {
  vertical-align: middle;
  align-content: stretch;
}

/* tree */
/* Radio Button */
.ui-radiobutton-box.ui-widget.ui-state-default {
  border: 2px solid #d6d6d6;
}

.ui-radiobutton-box {
  line-height: 0.875em;
}

/* Radio Button */
.red-border {
  border-right: 3px solid red;
}

.green-border {
  border-right: 3px solid darkgreen;
}

.blue-border {
  border-right: 3px solid blue;
}

/*Toggle Button */
.fixed-action-btn.horizontal {
  padding: 0 0 0 15px;
}

.fixed-action-btn {
  bottom: 0;
  padding-top: 15px;
  margin-bottom: 0;
  z-index: 998;
  position: absolute;
  display: inline-block;
  right: 24px;
}

.red {
  background-color: #f44336 !important;
}

.btn-large {
  text-decoration: none;
  color: #fff;
  background-color: #26a69a;
  text-align: center;
  letter-spacing: 0.5px;
  transition: 0.2s ease-out;
  cursor: pointer;
}

.btn-floating {
  display: inline-block;
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 40px;
  height: 40px;
  padding: 0;
  background-color: #26a69a;
  border-radius: 50%;
  transition: 0.3s;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  padding: 10px;
}

.fixed-action-btn.active ul {
  visibility: visible;
}

.fixed-action-btn ul {
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
  bottom: 64px;
  margin: 0;
  visibility: hidden;
}

.fixed-action-btn.horizontal ul {
  text-align: right;
  right: 64px;
  top: 60px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 150px;
  left: auto;
  width: 600px;
  background-color: $default;
}

.fixed-action-btn.horizontal ul li {
  display: inline-block;
  margin: 15px 15px 0 0;
}

.transparent {
  background-color: transparent;
}

.system-color.text {
  color: #4791cd;

  &:hover {
    color: #03a9f4 !important;
  }
}

.white.text {
  color: #fff !important;

  &:hover {
    color: #fff !important;
  }
}

.light-blue.bg {
  background-color: #03a9f4;
}

.orange-400.bg {
  background-color: #ffa726;
}

.blue-grey.bg {
  background-color: #607d8b;
}

.red-400.bg {
  background-color: #ef5350;
}

.green-400.bg {
  background-color: #66bb6a;
}

.purple-300.bg {
  background-color: #ba68c8;
}

/* Toggle Button */
/* tooltip */
.test-tooltip {
  background-color: red !important;
  color: black !important;
}

/* tooltip */
/* card */
@media screen and (min-width: 1620px) {
  .row.shift-up {
    > * {
      margin-top: -573px !important;
    }
  }
}

@media screen and (max-width: 1620px) {
  .card.feed-panel.large-card {
    height: 824px !important;
  }
}

.user-stats-card {
  .card-title {
    padding: 0 0 15px !important;
  }
}

.blurCalendar {
  height: 360px !important;
}

.col-xl-2.col-lg-6.hvr-bounce-in.main-card {
  padding: 0;
}

.card.medication-card {
  color: black !important;
}

.card:not(.standard-card) {
  position: relative !important;
  display: block !important;
  margin-bottom: 0rem !important;
  background-color: #fff !important;
  border: 1px solid #d7e0f1 !important;
  border-radius: 0 !important;

  &:not(.text-dark) {
    color: $default !important;
  }

  min-height: 110px;

  &.card-static-height {
    min-height: 460px;
    max-height: 460px;
  }

  .card-header {
    padding: 0.625rem 0.875rem !important;
    background-color: rgba(51, 122, 183, 0.82) !important;
    border-bottom: 1px solid rgba(51, 122, 183, 0.82) !important;
    height: auto !important;
    color: $default !important;
    opacity: 0.8;

    &:hover {
      color: rgba(51, 122, 183, 0.82) !important;
      background: $default !important;
    }
  }

  .card-icon {
    opacity: 1;
  }

  .title {
    padding: 5px 0;
  }
}

/* card */
p-panel {
  width: 100%;
}

/* Calnedar */
.fc-header.fc-widget-header {
  .fc-title {
    color: black !important;
  }
}

.fc-content {
  .fc-time {
    color: #fff;
  }
}

/*.fc-scroller.fc-day-grid-container {
    height: 100% !important;
}*/

.fc-title {
  color: #fff !important;
}

.fc-day-grid-event .fc-time {
  color: #fff !important;
}

a.fc-more {
  margin: 1px 3px !important;
  cursor: pointer !important;
  text-decoration: none !important;
  color: red !important;
}

.fc-basic-view .fc-body .fc-row {
  height: 100% !important;
}

/* Calnedar */
button.close {
  padding: 0 15px;
}

.shortuct-item {
  display: block;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  background: transparent;
  color: #6a6c6f !important;
  border: 0;
  border-bottom: 1px solid #ddd;

  &:hover {
    background-color: transparent;
    text-decoration: underline;
    color: $secondary-color !important;
  }

  &:focus {
    outline: 0 !important;
  }
}

.close-modal {
  width: 100%;
  margin: 15px 0 0 0;
  color: #fff;
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
}

/* Action Button */
.item-container {
  right: 20px;
  position: relative;
  width: 40px;
  height: 40px;
  background-color: #4791cd;
  border-radius: 90px;
  transition:
    width 1s,
    right 1s;
  overflow: hidden;
  /*box-shadow: 0px 5px 18px -2px rgba(0,0,0,0.7);*/
  float: right;

  &:hover {
    width: 600px;
    right: 0px;
  }
}

.item {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 90px;
  margin-right: 22.5px;
  z-index: 1;
  float: left;
  background-color: rgba(103, 113, 110, 0);
}

.highlight {
  position: absolute;
  width: 0;
  height: 0;
  margin: 45px;
  border-radius: 90px;
  transition: 0.1s;
  top: 0;
  left: 0;
}

.nav {
  position: relative;
  color: #2e3936;
  top: 45px;
  left: 45px;
  transition: font 0s;
  z-index: 3;
  color: #fff;
}

.anchor {
  top: 0;
  right: 0;
  z-index: 4;
  position: absolute;
  color: #fff;
  transition: transform 0.2s;
  transition-delay: 0.2s;
  width: 40px;
  height: 40px;
}

.anchor i {
  margin-left: 0px;
  margin-top: 10px;
}

.item-container:hover .anchor {
  transform: rotate(45deg);
}

.item-container:hover .nav {
  transition: 0.1s;
  top: 12px;
  left: 0;
}

.item:hover {
  background-color: #ffffff;
  color: black;
  transition: 0.2s;

  .item-action {
    color: #4791cd;
  }
}

.item:active .highlight {
  z-index: 2;
  margin: 0;
  width: 40px;
  height: 40px;
}

/* Action Button */
/* pick list*/
.ui-picklist-filter-container.ui-widget-content {
  border: 1px solid #d5d5d5;
}

ul.ui-widget-content.ui-picklist-list.ui-picklist-source.ui-corner-bottom,
ul.ui-widget-content.ui-picklist-list.ui-picklist-target.ui-corner-bottom {
  border: 1px solid #d5d5d5;
}

.picklist-row {
  margin: 15px 5px;
}

/* pick list*/
.gray-background {
  .ui-panel .ui-panel-content {
    padding: 0 !important;
  }

  .ui-panel {
    border: 1px solid #d5d5d5 !important;
  }

  .ui-panel-titlebar {
    background-color: #9e9e9e !important;
    color: #fff !important;
  }
}

.gray-background {
  .ui-panel .ui-panel-content {
    padding: 0 !important;
  }

  .ui-panel {
    border: 1px solid #d5d5d5 !important;
  }

  .ui-panel-titlebar {
    background-color: #9e9e9e !important;
    color: #fff !important;
  }
}

.light-blue-background {
  .ui-panel .ui-panel-content {
    padding: 0 !important;
  }

  .ui-panel {
    border: 1px solid #d5d5d5 !important;
  }

  .ui-panel-titlebar {
    background-color: #477f7e !important;
    color: #fff !important;
  }
}

.orange-background {
  .ui-panel .ui-panel-content {
    padding: 0 !important;
  }

  .ui-panel {
    border: 1px solid #d5d5d5 !important;
  }

  .ui-panel-titlebar {
    background-color: #e9af15 !important;
    color: black !important;
  }
}

.green-background-item {
  .ui-panel .ui-panel-content {
    padding: 0 !important;
  }

  .ui-panel {
    border: 1px solid #d5d5d5 !important;
  }

  .ui-panel-titlebar {
    background: #87ce4b !important;
    color: #fff !important;
  }

  .header-title {
    background-color: #54b050;
    color: #fff;
    padding: 5px;
  }

  .button-color {
    background-color: #54b050;
    border: #54b050;
  }
}

.blue-background-item {
  .ui-panel .ui-panel-content {
    padding: 0 !important;
  }

  .ui-panel {
    border: 1px solid #d5d5d5 !important;
  }

  .ui-panel-titlebar {
    background: #64c5f9 !important;
    color: #fff !important;
  }

  .header-title {
    background-color: #4991ce;
    color: #fff;
    padding: 5px;
  }

  .button-color {
    background-color: #4991ce;
    border: 1px solid #4991ce;
  }
}

.gray-background-item {
  .ui-panel .ui-panel-content {
    padding: 0 !important;
  }

  .ui-panel {
    border: 1px solid #d5d5d5 !important;
  }

  .ui-panel-titlebar {
    background: #898887 !important;
    color: #fff !important;
  }

  .header-title {
    background-color: #f6f5f5;
    color: #d2d2d2;
    padding: 5px;
  }
}

.orange-background-item {
  .ui-panel .ui-panel-content {
    padding: 0 !important;
  }

  .ui-panel {
    border: 1px solid #d5d5d5 !important;
  }

  .ui-panel-titlebar {
    background: #f1b217 !important;
    color: #fff !important;
  }

  .header-title {
    background-color: #eb8327;
    color: #fff;
    padding: 5px;
  }

  .button-color {
    background-color: #f1b217;
    border: 1px solid #f1b217;
  }
}

.info-details {
  position: fixed;
  min-height: 100%;
  background: #fff;
  color: #fff;
  z-index: 1040;
  left: 0;
  top: 50px;
  width: 350px;
  transition:
    visibility 0.1s,
    width 0.1s linear;
}

.info-collapse {
  width: 0 !important;
  transition:
    visibility 0.1s,
    width 0.1s linear;

  .hide-info {
    visibility: hidden;
    transition:
      visibility 0.1s,
      width 0.1s linear;
  }

  .info-title {
    visibility: hidden;
    transition:
      visibility 0.1s,
      width 0.1s linear;
  }

  .info-action {
    visibility: hidden;
    transition:
      visibility 0.1s,
      width 0.1s linear;

    h3 {
      visibility: hidden;
    }

    .list-group.vertical-steps {
      visibility: hidden;
      width: 0;
      transition:
        visibility 0.1s,
        width 0.1s linear;
    }
  }
}

.info-action {
  color: black;
  margin: 0;

  .col-sm-12 {
    border-bottom: 1px solid black;
    padding: 10px 0px;
    margin: 0;
    cursor: pointer;
  }
}

.hide-info {
  padding: 10px 0 0 0;
  margin: 0 -20px;
  border-radius: 50%;

  i {
    color: #757575;
  }

  .button-menu-toggle {
    border: 0;
    background-color: transparent;
    border-radius: 50%;
    width: 40px;
    height: 40px;

    &:hover {
      background-color: #e0e0e0;
    }
  }
}

.info-title {
  padding: 20px 0 0 0;
  color: black;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  color: rgba(102, 92, 92, 0.8) !important;
}

.notification-image {
  width: 15px;
}

.ui-steps .ui-steps-item {
  width: 25%;
}

.ui-steps.steps-custom {
  margin-bottom: 30px;
}

.ui-steps.steps-custom .ui-steps-item .ui-menuitem-link {
  height: 10px;
  padding: 0;
  background: #1976d2;
}

.ui-steps.steps-custom .ui-steps-item .ui-steps-number {
  background-color: #ffc107;
  color: transparent;
  display: inline-block;
  border-radius: 50%;
  margin-top: -5px;
  margin-bottom: 0px;
  height: 20px;
  width: 20px;
}

.ui-steps.steps-custom .ui-steps-item .ui-steps-title {
  color: #555555;
}

.list-group-item {
  cursor: pointer;
}

.red-label {
  label {
    margin: 0 !important;
    color: rgb(246, 103, 93) !important;
  }
}

.yellow-label {
  label {
    margin: 0 !important;
    color: #e6dc07 !important;
  }
}

.green-label {
  label {
    margin: 0 !important;
    color: rgb(103, 189, 106) !important;
  }
}

.light-gray-label {
  label {
    margin: 0 !important;
    color: #b6d8e8 !important;
  }
}

.dark-red-label {
  label {
    margin: 0 !important;
    color: rgb(187, 74, 66) !important;
  }
}

.ui-radiobutton-icon {
  margin-top: 0px !important;
  line-height: 0 !important;
}

.finger-print {
  border: 0;
  padding: 10px;
  width: 280px;
}

.dynamic-row {
  .ui-inputtext {
    border: 1px solid #ddd !important;
  }
}

.ui-fieldset-content {
  .row {
    border: 1px solid #ddd !important;
  }
}

img.ui-panel-images {
  width: 100% !important;
}

.ui-galleria-filmstrip-wrapper {
  width: 450px !important;
  height: 40px !important;
}

.ui-galleria-caption {
  top: 0 !important;
  bottom: auto !important;
  width: 100% !important;
}

/*.visit-admissions {
    position: absolute;
    width: 300px;
    left: 0;
    height: calc(100vh - 100px);
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;

    .header {
        margin: 0;
        border-bottom: 1px solid #ddd;
        padding: 10px 0;

        a {
            cursor: pointer;

            .internal-row {
                .ui-calendar {
                    margin: 0 !important;
                }

                .ui-widget-header .ui-state-default, .ui-widget-content .ui-state-default, .ui-state-default {
                    border: 1px solid #ddd !important;
                }
            }

            &:active {
                color: crimson;
            }
        }
    }
}*/
.kardex-table {
  .ui-datatable .ui-datatable-thead > tr > th,
  .ui-datatable .ui-datatable-tfoot > tr > td,
  .ui-datatable .ui-datatable-data > tr > td {
    padding: 10px !important;
  }
}

.component-result {
  overflow-x: auto;
  height: 90vh;
  padding: 0px;
  margin-top: 10px;
  padding: 1rem 1.1rem;

  a {
    width: 100%;
    padding: 0;
    cursor: pointer;
  }
}

/*main*/
/*:not(#search-bar)*/
main.ng2.scrollable-container {
  overflow: hidden;

  #search-bar {
    pointer-events: auto;
    opacity: 1;
    // border-bottom: 1px solid $primary-color;
    // background: #fff;
  }

  #search-item,
  .header-item {
    display: none;
  }
}

sidebar-notification-cmp {
  #search-bar {
    // padding-bottom: 50px;
    // padding-top: 0;
    // position: fixed;
    // top: 0;
    // width: 100%;
    // max-height: 75%;
    // pointer-events: none;
    // opacity: 0;
    // transition: opacity 0.5s;
    // transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);

    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.97);
    z-index: 1010;
    padding: 20px;
    overflow: auto;
    width: 100%;

    .search-title {
      display: none;
    }

    form {
      width: 100%;
      position: relative;
      max-height: 100%;
      margin-top: 20px;
      padding: 0;
      margin-bottom: 0px;
    }
  }
}

.selected-home-item {
  background: #fff;

  span {
    color: $secondary-color !important;
  }
}

.breadcrumb {
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  color: #282828;
  text-transform: uppercase;
  width: 100%;

  .active {
    color: $secondary-color;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    color: #282828;
    content: ">";
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.hm-icon {
  line-height: 36px;
}

.ui-datatable tbody {
  outline: 0;
}

//Action Table //
.action {
  i {
    cursor: pointer;
    color: #3a8fc8;
  }
}

.action-menu {
  position: absolute;
  width: 200px;
  z-index: 1060;
  float: left;
  margin: 0px;
  list-style: none;
  text-align: left;
  background-color: #edf0f5;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 2px 10px #00000033;
  background-clip: padding-box;
  padding: 0 10px;
  will-change: transform !important;
  transform: translate3d(-10px, 0px, 10px) !important;

  &.initial-transform {
    transform: none !important;
    inset: unset !important;
    right: 0 !important;
  }

  li {
    cursor: pointer;
    padding: 10px 0;
    border-bottom: 1px solid #dddddd;

    a {
      color: #000 !important;
    }

    i {
      padding: 0 10px;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

// Action Table //
//Patient Information //
#patient-name {
  color: black;
}

//Patient Information //
.group-box {
  border: 1px solid #ddd;
  padding: 10px 0;
}

.table-header-with-icon {
  a {
    color: #282828;

    i {
      padding: 0 5px 5px 10px;
      color: #5e5e5e;
    }
  }
}

.ui-datatable tbody > tr.ui-widget-content.ui-state-highlight {
  .action {
    i {
      color: #fff;
    }
  }
}

.background-white {
  background-color: #fff !important;
}

.uppercase {
  text-transform: uppercase;
}

.without-padding {
  padding: 0 !important;
}

.without-padding-bottom {
  padding-bottom: 0 !important;
}

.with-padding {
  padding: 15px;
}

.with-horizontal-padding {
  padding: 15px 0;
}

.with-cursor {
  cursor: pointer;
}

.red-color {
  color: #ed2024;
}

.yellow-color {
  color: #eedc00;
}

.blue-color {
  color: #80c4f2;
}

.green-color {
  color: #82e27d;
}

.dark-green-color {
  color: #5bb95d;
}

.orange-color {
  color: #f7a12a;
}

.black-color {
  color: black;
}

/* NEW FEATURES */

.dropdown-button {
  font-size: $default-text-size !important;
}

.ui-button-text-only .ui-button-text {
  padding: 1em !important;
}

.dashboard-calendar {
  .fc-center {
    padding-right: 30px;
  }

  .fc-corner-right {
    position: absolute !important;
    text-align: right;
    right: 0;
    width: calc(100% - 150px);
    margin-left: 100px !important;
    font-family: $default-font-family;
  }
}

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-size: $default-font-size !important;
}

// .ui-widget-content {
//   font-size: $default-font-size !important;
// }

.ui-confirmdialog {
  z-index: 2000 !important;
}

.ui-button-text-only .ui-button-text {
  padding: 1em !important;
}

.amcharts-chart-div {
  a {
    display: none !important;
  }
}

.without-margin {
  margin: 0 !important;
}

.ui-datepicker-buttonbar {
  .ui-button-text {
    padding: 0.25em 1em !important;
  }
}

.hide-part-of-element {
  input {
    background: transparent;
    border: 0;
    outline: none;
    width: 50px;
  }

  &:hover {
    input {
      width: 100%;
      background-color: transparent;
    }

    span {
      &::after {
        content: "";
      }
    }
  }

  span {
    &::after {
      content: " ....";
    }
  }
}

button.ui-spinner-button {
  border: 0 !important;
  background: transparent !important;

  span {
    color: $primary-color !important;
  }

  &:hover {
    background: $primary-color !important;

    span {
      color: #fff !important;
    }
  }
}

.ui-autocomplete .ui-autocomplete-input {
  background-color: #ffffff;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.07);
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  height: 35px;
  padding: 9px 12px;
  line-height: normal;
  vertical-align: middle;
  min-height: 35px;
  transition: all 0.12s ease;
  box-shadow: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  transition: background 0.2s linear 0s;
}

.ui-column-filter {
  &.ui-dropdown {
    label.ui-dropdown-label {
      cursor: pointer;
      text-align: left;
      background-color: #ffffff;
      background-image: none;
      border: 1px solid rgba(0, 0, 0, 0.07);
      -webkit-appearance: none;
      color: #2c2c2c;
      outline: 0;
      height: 35px;
      padding: 9px 12px;
      line-height: normal;
      vertical-align: middle;
      min-height: 35px;
      transition: all 0.12s ease;
      box-shadow: none;
      border-radius: 2px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      transition: background 0.2s linear 0s;
    }

    .ui-dropdown-trigger.ui-state-default.ui-corner-right {
      padding-top: 0.5em;
      height: 90%;
      margin: 1px 1px;
    }
  }
}

.ui-column-spinner-filter {
  input {
    background-color: #ffffff;
    background-image: none;
    border: 1px solid rgba(0, 0, 0, 0.07);
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    height: 35px;
    padding: 9px 12px;
    line-height: normal;
    vertical-align: middle;
    min-height: 35px;
    transition: all 0.12s ease;
    box-shadow: none;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    transition: background 0.2s linear 0s;
  }
}

.action-dropdown-left {
  ul {
    position: absolute;
    background: #edf0f5;
    right: 175px;
    width: 250px;
    top: 0;

    li {
      text-decoration: none;
    }
  }
}

.ui-table .ui-table-tbody > tr:hover {
  background-color: #e0e0e0 !important;
  color: #212121 !important;
}

.view-caption-action {
  display: none;
}

.view-caption-boder-bottom {
  border-bottom: 0;
}

@media screen and (max-width: 40em) {
  .view-caption-action {
    display: block;
  }

  .view-caption-boder-bottom {
    border-bottom: 1px solid #ffffff;
  }
}

.column-small-width {
  width: 6rem;
}

.ui-table-wrapper {
  a {
    color: #2399e5 !important;
  }

  .ui-calendar {
    margin: 0 !important;
  }
}

.ui-spinner.ui-widget.ui-corner-all {
  width: 100%;
}

.header-item-link {
  a {
    color: $primary-color !important;
    text-decoration: underline !important;
  }
}

.ui-chkbox-box.ui-state-active,
.ui-radiobutton-box.ui-state-active {
  color: #fff !important;
}

.light-blue-background-item {
  .ui-panel-titlebar {
    background: #607d8b !important;
    color: #fff !important;

    border-bottom: 1px solid #fff;
  }

  .button-color {
    background-color: #607d8b;
    border: 1px solid #607d8b;
  }
}

.light-blue-background-item .header-title {
  background-color: #eceff1;
  color: #000;
  padding: 5px;
}

.light-blue-background-item .ui-panel .ui-panel-content {
  padding: 0 !important;
}

.light-blue-background-item .ui-panel-content-wrapper {
  border: 1px solid #d5d5d5 !important;
}

.sub-string-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.absolute-calendar-icon {
  .ui-datepicker-trigger {
    position: absolute;
    right: 5px !important;
    top: 5px;
    left: auto !important;
  }
}

.user-Profile {
  .user-name {
    .dropdown-backdrop {
      position: relative !important;
    }
  }
}

.ui-growl {
  z-index: 13000 !important;
}

.form-group-calendar {
  .ui-calendar {
    margin-top: 0 !important;

    .ui-inputtext {
      background-color: #ffffff;
      background-image: none;
      border: 1px solid rgba(0, 0, 0, 0.07);
      -webkit-appearance: none;
      color: #2c2c2c;
      outline: 0;
      height: 35px;
      padding: 9px 12px;
      line-height: normal;
      vertical-align: middle;
      min-height: 35px;
      -webkit-transition: all 0.12s ease;
      transition: all 0.12s ease;
      box-shadow: none;
      border-radius: 2px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      -webkit-transition: background 0.2s linear 0s;
      transition: background 0.2s linear 0s;
    }
  }
}

.form-group-dropdown {
  .ui-dropdown.ui-widget.ui-state-default.ui-corner-all.ui-helper-clearfix {
    border: 1px solid rgba(0, 0, 0, 0.07);
  }

  .ui-dropdown-label {
    background-color: #ffffff;
    background-image: none;
    border: 0px solid rgba(0, 0, 0, 0.07);
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    height: 27px;
    padding: 9px 12px;
    line-height: normal;
    vertical-align: middle;
    min-height: 27px;
    -webkit-transition: all 0.12s ease;
    transition: all 0.12s ease;
    box-shadow: none;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-transition: background 0.2s linear 0s;
    transition: background 0.2s linear 0s;
  }
}

.ui-tooltip {
  z-index: 99999 !important;
}

.href-color {
  color: #0275d8 !important;
}

.disable-calendar {
  pointer-events: none;

  input {
    background-color: #e1e1e1 !important;
    opacity: 1;
    cursor: not-allowed !important;
    color: #8e8e8e !important;
  }
}

// HACK
.col-button > .ui-cell-data > button,
.col-button > .ui-column-title > button {
  padding: 0 0.5rem;
}

// HACK

.second-dialog {
  top: 25%;
  max-width: 50%;
}

.disable-items:not(.silent-loader) {
  * {
    pointer-events: none !important;
  }
}

.disable-loader {
  .loader-default-wrapper {
    display: none !important;
  }
}

.silent-loader {
  .loader-default-wrapper {
    display: none !important;
  }
}

span.cke_toolbar_break {
  display: none;
}

.cke_voice_label,
.cke_path {
  display: none;
}

.chart-div {
  width: 100%;
  height: 500px;
}

.report-full-screen {
  .al-sidebar,
  ba-page-top,
  ba-notification-favorite,
  .page-top,
  .sidebar-notification,
  .setting-nav {
    display: none !important;
  }

  .al-main,
  .al-content {
    margin-left: 0 !important;
    padding: 0 !important;
  }
}

ckeditor {
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-width: 1px;
  }
}

.disable-tab {
  position: relative;
  pointer-events: none;

  &:before {
    position: absolute;
    margin: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 3px;
    text-align: center;
    z-index: 1;
    padding-top: 150px;
    font-size: 2.5rem;
    font-weight: bolder;
    text-transform: uppercase;
  }

  &.patient {
    &::before {
      content: "Please Select a Patient";
    }
  }

  &.visit {
    &::before {
      content: "Please Select a Visit";
    }
  }

  &.deposit {
    &::before {
      content: "No Available Deposits";
      color: red;
    }
  }
}

.modal-alert {
  padding: 0 !important;
}

.modal-alert .modal-dialog {
  max-width: 100% !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 3.5rem);
  -webkit-transform: none !important;
  transform: none !important;
}

.modal-alert .modal-content {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0;
  padding: 1.5rem 1rem 1rem;
}

.modal-alert .modal-title {
  font-size: 1.5rem;
  font-weight: 300;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  color: #fff;
}

.modal-alert .modal-body,
.modal-alert .modal-footer,
.modal-alert .modal-header {
  width: 100%;
  max-width: 992px;
  margin: 0 auto;
  padding: 0;
  color: #fff !important;
  position: relative;
  border: 0 !important;
}

.modal-alert .close {
  color: #fff;
  text-shadow: 0 1px 0 #000;
}

.hide-input-border {
  input {
    border: 0;
    outline: none;
    width: 100%;
    background-color: transparent;
  }
}

.header-full-div-tab {
  .p-tabview-nav-link {
    padding: 0 !important;
    height: 40px;
  }
}

#list-of-action {
  display: inline-block;
  padding: 0px 10px;
  color: rgb(51, 51, 51);
  cursor: pointer;

  button {
    font-style: normal;
    background: #fff;
    border: 0;
    color: #6a6c6f;
    width: 200px;
    text-align: left;

    > i {
      color: $secondary-color;
      padding: 5px;
      border-left: 1px solid #ddd;
      position: absolute;
      width: 30px;
      height: 100%;
      right: 10px;
      text-align: center;
    }
  }

  ul {
    background-color: #fff;
    top: 25px;
    left: 10px;
    max-width: 100%;
    padding: 0 0;
    margin: 0;
    min-width: 200px;
    border-radius: 0;
    border: 0;
    border-top: 1px solid #ddd;

    > li {
      text-align: left;
      padding: 5px 0;

      a {
        display: block;
        padding: 4px 20px;
        white-space: nowrap;
        color: #6a6c6f;
        font-size: 12px;
        font-weight: 400;
        font-size: 1rem;

        i {
          line-height: 100%;
          vertical-align: top;
          width: 28px;
          padding: 0 10px 0 0px;
          color: $secondary-color;
        }
      }
    }

    > li:last-child {
      a {
        border-bottom: 0;
      }
    }
  }
}

.custom-scroll,
.custom-scrollbar {
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.custom-scroll::-webkit-scrollbar-track-piece,
.custom-scrollbar::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

.custom-scroll::-webkit-scrollbar-thumb:vertical,
.custom-scrollbar::-webkit-scrollbar-thumb:vertical {
  background-color: #666;
}

.custom-scroll::-webkit-scrollbar,
.custom-scrollbar::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.custom-scroll::-webkit-scrollbar-corner,
.custom-scrollbar::-webkit-scrollbar-corner {
  width: 40px;
}

.custom-scroll::-webkit-scrollbar-thumb:vertical,
.custom-scrollbar::-webkit-scrollbar-thumb:vertical {
  background-color: #666;
}
