@import "./conf/_variables.scss";
@import "./conf/colorSchemes/_ng2.scss";

.form-group-default {
  background-color: transparent;
  position: relative;
  padding-top: 7px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 4px;
}

.modal-lg {
  max-width: 80%;
}

.modal-md-lg {
  max-width: 70%;
}

.modal-md {
  max-width: 50%;
}

.modal-sm {
  max-width: 25%;
}

.ui-autocomplete {
  width: 100% !important;
}

.ui-multiselect-label {
  margin-bottom: 0px !important;
  padding: 0px 10px;
  height: 35px;
}

.ui-multiselect.ui-state-focus,
.ui-multiselect.ui-state-focus {
  box-shadow: none !important;
}

.ui-multiselect .ui-multiselect-trigger .fa {
  margin-top: 8px;
}

.ui-multiselect .ui-state-default {
  color: $primary-color;
}

.ui-multiselect.ui-widget.ui-state-default.ui-corner-all {
  width: 100%;
}

.ui-multiselect.ui-widget.ui-state-default.ui-corner-all.ui-helper-clearfix {
  border-radius: 0px;
  box-shadow: 0px 0px 5px 0px #e1e1e1;
}

.ui-multiselect-label.ui-placeholder {
  color: #959595 !important;
}

.ui-multiselect.ui-state-disabled,
.ui-multiselect.ui-state-disabled .ui-multiselect-label,
.ui-multiselect.ui-state-disabled .ui-corner-right {
  background-color: #e1e1e1 !important;
  opacity: 1;
  cursor: not-allowed !important;
  color: #8e8e8e !important;
}

.ui-corner-all {
  border-radius: 0px !important;
}

textarea {
  -webkit-box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-text-fill-color: #212121 !important;
  background-color: #ffffff !important;
  background-image: none !important;
  color: #212121 !important;
}

textarea.ui-inputtext.ui-corner-all.ui-state-default.ui-widget {
  box-shadow: 0px 0px 5px 0px #e1e1e1;
}

.form-group.row.form-group-default .form-control {
  height: 35px;
  margin-top: 0;
}

.form-group {
  margin-bottom: 0;
  
  .p-multiselect-label {
    color: $primeng-primary-color-text;
  }
}

.form-group-default .form-control {
  border: none;
  height: 25px;
  min-height: 25px;
  padding: 0;
  margin-top: -4px;
  background: none;
}

.form-horizontal {
  .form-group-default {
    background: inherit;
    border: none !important;
    overflow: inherit;
  }

  .form-group.row.form-group-default {
    padding-top: 0px;

    label {
      border: none !important;
    }

    .switch-small {
      margin-top: 5px;
    }

    input {
      padding: 0px 10px;
      box-shadow: 0px 0px 5px 0px #e1e1e1;
      background-color: white;
      border-radius: 0px;
      height: 35px;
    }
  }

  .form-group.row.form-group-default .col-lg-3,
  .form-group.row.form-group-default .col-md-6,
  .form-group.row.form-group-default .col-sm-12 {
    padding: 5px 10px 5px 10px;
  }

  .form-control[disabled],
  fieldset[disabled] .form-control {
    background-color: #e1e1e1 !important;
  }
}

.has-error > input:not([type=checkbox]):not([type=radio]),
.has-error > p-inputNumber .p-inputnumber-input {
  border: 0 !important;
  border-bottom: 1px solid $danger !important;
  border-radius: 0;
}

.has-error > p-calendar .ui-inputtext {
  border: 0 !important;
  border-bottom: 1px solid $danger !important;
  border-radius: 0;
}

.has-error > p-dropdown .ui-dropdown.ui-widget.ui-state-default.ui-corner-all.ui-helper-clearfix {
  border: 0 !important;
  border-bottom: 1px solid $danger !important;
  border-radius: 0;
}

.has-error {
  .p-dropdown, .p-multiselect {
    border: 0 !important;
    border-bottom: 1px solid #e52222 !important;
    border-radius: 0;
    box-shadow: 0px 0px 5px 0px #d4d4d4;
  }
}

.ui-tooltip-text.ui-shadow.ui-corner-all {
  background-color: #4b4b4b !important;
}

.has-error > textarea {
  border: 0 !important;
  border-bottom: 1px solid $danger !important;
}

form {
  display: block;
  margin-top: 0em;
}

.m-t-10 {
  margin-top: 10px;
}

p {
  display: block;
  letter-spacing: 0.01em;
  line-height: 22px;
  margin: 0px 0px 10px 0px;
  font-style: normal;
  white-space: normal;
}

.input-group-addon {
  background: #f0f0f0;
  border: 1px solid rgba(0, 0, 0, 0.07);
  color: rgba(98, 98, 98, 0.47);
  padding: 6px 9px;
  display: table-cell;
  border-radius: 3px;
  transition: border 0.2s linear 0s, box-shadow 0.2s linear 0s, color 0.2s linear 0s, box-shadow 0.2s linear 0s,
    background 0.2s linear 0s;
}

.form-control {
  background-color: #ffffff;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.07);
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  height: 35px;
  padding: 9px 12px;
  line-height: normal;
  vertical-align: middle;
  min-height: 35px;
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: background 0.2s linear 0s;
  transition: background 0.2s linear 0s;
}

.input-right {
  float: right;
  direction: rtl;
}

.input-group-addon:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.07);
  border-left: 0;
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
}

.windows h1,
.windows h2,
.windows h3,
.windows h4,
.windows h5 {
  letter-spacing: normal;
}

.windows h3 {
  line-height: 33px;
}

.radio label,
.checkbox label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px !important;
  margin-right: 15px;
}

input[type="radio"],
input[type="checkbox"] {
  margin-top: 1px 0 0;
  line-height: normal;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

textarea.ui-inputtext.ui-corner-all.ui-state-default.ui-widget {
  border: 1px solid #d6d6d6;
}

.label {
  border-radius: 0;
}

.label-primary {
  background: $primary;
}

.label-info {
  background: $primary-light;
}

.label-success {
  background: $success;
}

.label-warning {
  background: $warning;
}

.label-danger {
  background: $danger;
}

.form-horizontal {
  padding: 0px 15px;
  width: 100%;
  label {
    line-height: 34px;
    margin-bottom: 0;
    padding-top: 0 !important;
  }
}

.form-group {
  label {
    margin-bottom: 5px;
    color: $content-text;
  }
}

.form-control {
  color: $content-text;
  @include placeholderStyle($content-text, 0.7);
  /*border: 1px solid $input-border;*/
  /*border-radius: 5px;*/
  /*background-color: $default;*/
  /*box-shadow: none;*/
  &:focus {
    /*color: $content-text;
        box-shadow: none;*/
    /*border-color: $primary-bg;*/
    /*background: $input-background;*/
  }
}

select.form-control {
  padding-left: 8px;
}

select.form-control:not([multiple]) option {
  color: $dropdown-text;
}

select.form-control[multiple] option {
  color: $default-text;
}

textarea {
  width: 100%;
}

.form-inline {
  .form-group {
    input {
      width: 100%;
    }

    label {
      margin-right: 12px;
    }
  }

  button[type="submit"] {
    margin-left: 12px;
  }
}

textarea {
  border: 1px solid #475258;
}

@mixin setSwitchBorder($color) {
  .bootstrap-switch.bootstrap-switch-on {
    border-color: $color;
  }
}

.switch-container {
  display: inline-block;

  &.primary {
    @include setSwitchBorder($primary);
  }

  &.success {
    @include setSwitchBorder($success);
  }

  &.warning {
    @include setSwitchBorder($warning);
  }

  &.danger {
    @include setSwitchBorder($danger);
  }

  &.info {
    @include setSwitchBorder($primary-light);
  }
}

.input-group {
  width: 100%;
  margin-bottom: 15px;

  & > span {
    border-radius: 0;
  }
}

.nowrap {
  white-space: nowrap;
}

.cut-with-dots {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

@mixin customInput($color) {
  & > span {
    &:before {
      color: $color;
    }

    &:hover {
      &:before {
        border-color: $color;
      }
    }
  }
}

label.custom-input-primary {
  @include customInput($primary);
}

label.custom-input-success {
  @include customInput($success);
}

label.custom-input-warning {
  @include customInput($warning);
}

label.custom-input-danger {
  @include customInput($danger);
}

.form-horizontal {
  .radio,
  .radio-inline {
    padding-top: 0;
  }
}

.input-demo {
  line-height: 25px;
}

td.col-button {
  text-align: center;
}

@mixin validationState($color, $focusColor) {
  .control-label {
    color: $content-text;
  }

  .form-control {
    border: 1px solid $color;

    &:focus {
      box-shadow: none;
      border-color: $focusColor;
    }
  }

  label.custom-checkbox {
    color: $color;

    & > span {
      &:before {
        color: $color;
      }

      &:hover {
        &:before {
          border-color: $color;
        }
      }
    }
  }

  .form-control-feedback {
    color: $color;
  }

  .input-group-addon {
    background-color: $color;
    color: $label-text;
  }
}

.input-group-addon {
  line-height: inherit;
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

.has-feedback {
  .form-control {
    padding-right: 42.5px;
  }

  label ~ .form-control-feedback {
    top: 19px;
  }
}

.bootstrap-select {
  .btn-default {
    &:focus {
      color: $default-text;
    }
  }
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: $input-background;
  color: $disabled;
  border-color: $disabled-bg;
  @include placeholderStyle($default-text, 0.5);
}

.form-control-rounded {
  border-radius: 16px;
}

.help-block {
  color: $help-text;
  vertical-align: sub;
}

.help-block.error-block {
  display: none;

  .has-error &.basic-block {
    display: block;
  }
}

@mixin groupAddon($color) {
  background: $color;
  color: $label-text;
  border-color: $color;
}

.input-group-addon-danger {
  @include groupAddon($danger);
}

.input-group-addon-warning {
  @include groupAddon($warning);
}

.input-group-addon-success {
  @include groupAddon($success);
}

.input-group-addon-primary {
  @include groupAddon($primary);
}

.checkbox-demo-row {
  margin-bottom: 12px;
}

.dropdown-menu {
  border-radius: 5px;
}

.dropdown button.btn.btn-default.dropdown-toggle {
  color: $content-text;
}

.has-success {
  @include validationState($success-bg, $success);
  position: relative;
}

.has-warning {
  @include validationState($warning-bg, $warning);
  position: relative;
}

.has-error {
  @include validationState($danger-bg, $danger);
  position: relative;
}

.bootstrap-tagsinput {
  color: $content-text;
  background-color: $input-background;
  border: 1px solid $input-border;
  border-radius: 5px;
  box-shadow: none;
  max-width: 100%;
  line-height: 26px;
  width: 100%;

  &.form-control {
    display: block;
    width: 100%;
  }

  .tag {
    border-radius: 3px;
    padding: 4px 8px;

    & [data-role="remove"]:hover {
      box-shadow: none;
    }
  }

  input {
    background-color: $input-background;
    border: 1px solid $input-border;
    border-radius: 5px;
    @include placeholderStyle($default-text, 0.8);
    line-height: 22px;
    min-width: 53px;
  }
}

.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #02b5f7;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.progress-bar-striped,
.progress-striped .progress-bar {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px;
}

.progress-bar.active,
.progress.active .progress-bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.has-success .input-group-addon {
  border: none;
}

.input-group > span.addon-left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.input-group > span.addon-right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.input-group-btn {
  &:not(:first-child) {
    > .btn,
    > .btn-group {
      margin-left: 0;
    }
  }

  > .btn {
    line-height: 1.56;
  }
}

.with-primary-addon:focus {
  border-color: $primary;
}

.with-warning-addon:focus {
  border-color: $warning;
}

.with-success-addon:focus {
  border-color: $success;
}

.with-danger-addon:focus {
  border-color: $danger;
}

.sub-little-text {
}

.rating {
}

rating-inputs span {
  vertical-align: middle;
}

.col-icon {
  width: 38px;
  text-align: center;
}

.icon-header {
  text-align: left;
}

.dialog-center > .ui-dialog {
  width: 95%;
  top: 5% !important;
  margin: 1% 3%;
  left: 0 !important;
  height: 90% !important;
}

.ui-dialog .ui-dialog-content {
  height: 90%;
}

.chart {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 90px;
  text-align: center;
}

.chart canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.percent {
  display: inline-block;
  line-height: 90px;
  z-index: 2;
  color: #283e4a;
}

.percent:after {
  content: "%";
  margin-left: 0.1em;
}

.modal-content {
  background-color: #fff;
}

.ui-dropdown {
  width: 100% !important;
}

.empty-form-control {
  background: transparent;
  border: 0;
  width: 100%;
  outline: none;
}

input:focus,
.ui-dropdown:focus {
  box-shadow: 0px 0px 5px #1f89ce !important;
}

.custom-form-group {
  .ui-dropdown.ui-widget.ui-state-default.ui-corner-all.ui-helper-clearfix {
    background-color: #ffffff;
    background-image: none;
    border: 1px solid rgba(0, 0, 0, 0.07);
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    height: 35px;
    padding: 5px 10px;
    line-height: normal;
    vertical-align: middle;
    min-height: 35px;
    transition: all 0.12s ease;
    box-shadow: none;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    transition: background 0.2s linear 0s;
  }
  .ui-calendar {
    margin: 0 !important;
  }
  .ui-calendar.ui-widget-header .ui-state-default,
  .ui-calendar > .ui-state-default {
    background-color: #ffffff;
    background-image: none;
    border: 1px solid rgba(0, 0, 0, 0.07);
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    height: 35px;
    padding: 5px 10px;
    line-height: normal;
    vertical-align: middle;
    min-height: 35px;
    transition: all 0.12s ease;
    box-shadow: none;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    transition: background 0.2s linear 0s;
  }
  label.ui-chkbox-label {
    margin-bottom: 0.1rem;
    padding-left: 0.2rem;
  }
}
