@font-face {
    font-family: 'Open-Sans';
    src: url("assets/fonts/open-sans/open-sans.eot");
    src: url("assets/fonts/open-sans/open-sans.eot") format("embedded-opentype"),
        url("assets/fonts/open-sans/open-sans.woff") format("woff"),
        url("assets/fonts/open-sans/open-sans.ttf") format("truetype"),
        url("assets/fonts/open-sans/open-sans.woff2") format("woff2"),
        url("assets/fonts/open-sans/open-sans.svg") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Scheherazade';
    src: url("assets/fonts/scheherazade/scheherazade.eot");
    src: url("assets/fonts/scheherazade/scheherazade.eot") format("embedded-opentype"),
        url("assets/fonts/scheherazade/scheherazade.woff") format("woff"),
        url("assets/fonts/scheherazade/scheherazade.ttf") format("truetype"),
        url("assets/fonts/scheherazade/scheherazade.woff2") format("woff2"),
        url("assets/fonts/scheherazade/scheherazade.svg") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: url("assets/fonts/ubuntu/ubuntu.eot");
    src: url("assets/fonts/ubuntu/ubuntu.eot") format("embedded-opentype"),
        url("assets/fonts/ubuntu/ubuntu.woff") format("woff"),
        url("assets/fonts/ubuntu/ubuntu.ttf") format("truetype"),
        url("assets/fonts/ubuntu/ubuntu.woff2") format("woff2"),
        url("assets/fonts/ubuntu/ubuntu.svg") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Cormorant-Unicase';
    src: url("assets/fonts/cormorant-unicase/cormorant-unicase.eot");
    src: url("assets/fonts/cormorant-unicase/cormorant-unicase.eot") format("embedded-opentype"),
        url("assets/fonts/cormorant-unicase/cormorant-unicase.woff") format("woff"),
        url("assets/fonts/cormorant-unicase/cormorant-unicase.ttf") format("truetype"),
        url("assets/fonts/cormorant-unicase/cormorant-unicase.woff2") format("woff2"),
        url("assets/fonts/cormorant-unicase/cormorant-unicase.svg") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Light';
    src: url("assets/fonts/roboto-light/roboto-light.eot");
    src: url("assets/fonts/roboto-light/roboto-light.eot") format("embedded-opentype"),
        url("assets/fonts/roboto-light/roboto-light.woff") format("woff"),
        url("assets/fonts/roboto-light/roboto-light.ttf") format("truetype"),
        url("assets/fonts/roboto-light/roboto-light.woff2") format("woff2"),
        url("assets/fonts/roboto-light/roboto-light.svg") format("svg");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url("assets/fonts/roboto-reqular/roboto-reqular.eot");
    src: url("assets/fonts/roboto-reqular/roboto-reqular.eot") format("embedded-opentype"),
        url("assets/fonts/roboto-reqular/roboto-reqular.woff") format("woff"),
        url("assets/fonts/roboto-reqular/roboto-reqular.ttf") format("truetype"),
        url("assets/fonts/roboto-reqular/roboto-reqular.woff2") format("woff2"),
        url("assets/fonts/roboto-reqular/roboto-reqular.svg") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url("assets/fonts/roboto-bold/roboto-bold.eot");
    src: url("assets/fonts/roboto-bold/roboto-bold.eot") format("embedded-opentype"),
        url("assets/fonts/roboto-bold/roboto-bold.woff") format("woff"),
        url("assets/fonts/roboto-bold/roboto-bold.ttf") format("truetype"),
        url("assets/fonts/roboto-bold/roboto-bold.woff2") format("woff2"),
        url("assets/fonts/roboto-bold/roboto-bold.svg") format("svg");
    font-weight: 900;
    font-style: normal;
}

.image-viewer {
    position: fixed;
    top: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.65);
    left: 0;
    width: 100%;
    height: 100%;
    border: 10px solid #696868;
    z-index: 999999;
}

.image-viewer-content {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0px;
    left: 0;
    background: rgba(0, 0, 0, 0.65);

    img {
        overflow: auto;
        height: auto;
        box-shadow: none;
        max-width: 90%;
        max-height: 80%;
        margin-top: 5%;
        transition: all 0.7s ease-out;
    }
}

.image-scan {
    margin-top: 10px;
    border: 1px solid #e7dcdc;

    .image-scan-area {
        height: 200px;
        background-color: white;
    }

    .image-scan-button {
        padding-left: 0px;
    }
}

/*.close-button {
    position: absolute;
    width: 2.4rem;
    height: 2.4rem;
    right: 0.4rem;
    top: .8rem;
    cursor: pointer;
    transition: all .4s ease;
    opacity: .5;
    z-index: 2;

    &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        height: 2rem;
        width: .12rem;
        top: 0rem;
        right: 1rem;
        background-color: #fff;
        border-radius: 2rem;
        -webkit-transform-origin: center;
        transform-origin: center;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }

    &:hover {
        opacity: .8;
    }
}*/

.zoom-icon {
    position: absolute;
    right: 1rem;
    cursor: pointer;
    transition: all 0.4s ease;
    opacity: 0.5;
    z-index: 2;
    color: #fff !important;

    &.close {
        top: 1rem;
    }

    &.alt {
        top: 4rem;
    }

    &.in {
        top: 7rem;
    }

    &.out {
        top: 10rem;
    } 
}

.ui-fileupload-row img {
    border: none;
}

.ui-fileupload-buttonbar .ui-button:nth-of-type(2) {
    display: none !important;
}
