@import "./conf/_variables.scss";

.size-18 {
    font-size: 18px !important;
}

.al-main {
    font-size: $default-font-size;
}

.ui-helper-reset {
    font-size: $default-font-size;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: $default-font-family;
}

h1 {
    font-size: 30px;
}

h2 {
    font-size: 28px;
}

h3 {
    font-size: 25px;
}

h4 {
    font-size: 22px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 16px;
}

.display-1 {
    font-size: 5rem;
    font-weight: 300;
    line-height: 1.57;
}

.display-2 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.57;
}

.display-3 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.57;
}

.display-4 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.57;
}
