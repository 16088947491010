/* font-size */
.fs-5 {
  font-size: 5px !important;
}

.fs-6 {
  font-size: 6px !important;
}

.fs-7 {
  font-size: 7px !important;
}

.fs-8 {
  font-size: 8px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-21 {
  font-size: 21px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-23 {
  font-size: 23px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-27 {
  font-size: 27px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-29 {
  font-size: 29px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-31 {
  font-size: 31px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-33 {
  font-size: 33px !important;
}

.fs-34 {
  font-size: 34px !important;
}

.fs-35 {
  font-size: 35px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-37 {
  font-size: 37px !important;
}

.fs-38 {
  font-size: 38px !important;
}

.fs-39 {
  font-size: 39px !important;
}

.fs-40 {
  font-size: 40px !important;
}

/* font-size */

/* font-weight */

.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.fw-bold {
  font-weight: bold !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: normal !important;
}

.fw-normal {
  font-weight: normal !important;
}

/* font-weight */

/* text-decoration */

.decoration-dashed {
  text-decoration: dashed !important;
}

.decoration-dotted {
  text-decoration: dotted !important;
}

.decoration-double {
  text-decoration: double !important;
}

.decoration-line-through {
  text-decoration: line-through !important;
}

.decoration-overline {
  text-decoration: overline !important;
}

.decoration-solid {
  text-decoration: solid !important;
}

.decoration-underline {
  text-decoration: underline !important;
}

.decoration-wavy {
  text-decoration: wavy !important;
}

.decoration-none {
  text-decoration: none !important;
}

/* text-decoration */

/* font-family */

.ff-opensans {
  font-family: "Open Sans" !important;
}

.ff-segoeui {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif !important;
}

.ff-ubunto {
  font-family: "ubunto" !important;
}

.ff-cormorantunicase {
  font-family: "Cormorant Unicase" !important;
}

/* font-family */

/* element style */

.no-border {
  border: none !important;
}

.full-width {
  width: 100% !important;
}

.half-width {
  width: 45% !important;
}

.full-height {
  height: 100% !important;
}

.half-height {
  height: 50% !important;
}

.auto-height {
  height: auto !important;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-pointer-events {
  pointer-events: none;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.align-content-center {
  align-items: center !important;
}

.align-content-end {
  align-items: end !important;
}

.d-flex {
  display: flex !important;
}

.d-contents {
  display: contents !important;
}

.d-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.align-content-center {
  align-items: center !important;
}

.d-flex-row {
  flex-direction: row !important;
}

.d-flex-column {
  flex-direction: column !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.align-item-center {
  align-items: center !important;
}

.flex-no-wrap {
  flex-wrap: nowrap !important;
}

.d-none {
  display: none !important;
}

.d-inline-grid {
  display: inline-grid !important;
}
/* element style */

/* padding */

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.plr-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ptb-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.plr-5 {
  padding: 0 5px !important;
}

.plr-10 {
  padding: 0 10px !important;
}

.plr-15 {
  padding: 0 15px !important;
}

.plr-20 {
  padding: 0 20px !important;
}

.plr-25 {
  padding: 0 25px !important;
}

.plr-30 {
  padding: 0 30px !important;
}

.plr-35 {
  padding: 0 35px !important;
}

.plr-40 {
  padding: 0 40px !important;
}

.plr-45 {
  padding: 0 45px !important;
}

.plr-50 {
  padding: 0 50px !important;
}

.plr-55 {
  padding: 0 55px !important;
}

.plr-60 {
  padding: 0 60px !important;
}

.plr-65 {
  padding: 0 65px !important;
}

.plr-70 {
  padding: 0 70px !important;
}

.plr-75 {
  padding: 0 75px !important;
}

.plr-80 {
  padding: 0 80px !important;
}

.plr-85 {
  padding: 0 85px !important;
}

.plr-90 {
  padding: 0 90px !important;
}

.plr-95 {
  padding: 0 95px !important;
}

.plr-100 {
  padding: 0 100px !important;
}

.ptb-5 {
  padding: 5px 0 !important;
}

.ptb-10 {
  padding: 10px 0 !important;
}

.ptb-15 {
  padding: 15px 0 !important;
}

.ptb-20 {
  padding: 20px 0 !important;
}

.ptb-25 {
  padding: 25px 0 !important;
}

.ptb-30 {
  padding: 30px 0 !important;
}

.ptb-35 {
  padding: 35px 0 !important;
}

.ptb-40 {
  padding: 40px 0 !important;
}

.ptb-45 {
  padding: 45px 0 !important;
}

.ptb-50 {
  padding: 50px 0 !important;
}

.ptb-55 {
  padding: 55px 0 !important;
}

.ptb-60 {
  padding: 60px 0 !important;
}

.ptb-65 {
  padding: 65px 0 !important;
}

.ptb-70 {
  padding: 70px 0 !important;
}

.ptb-75 {
  padding: 75px 0 !important;
}

.ptb-80 {
  padding: 80px 0 !important;
}

.ptb-85 {
  padding: 85px 0 !important;
}

.ptb-90 {
  padding: 90px 0 !important;
}

.ptb-95 {
  padding: 95px 0 !important;
}

.ptb-100 {
  padding: 100px 0 0 !important;
}

.padding-5 {
  padding: 5px !important;

  &.padding-top {
    padding: 5px 0 0 0 !important;
  }

  &.padding-left {
    padding: 0 0 0 5px !important;
  }

  &.padding-right {
    padding: 0 5px 0 0 !important;
  }

  &.padding-bottom {
    padding: 0 0 5px 0 !important;
  }
}

.padding-10 {
  padding: 10px !important;

  &.padding-top {
    padding: 10px 0 0 0 !important;
  }

  &.padding-left {
    padding: 0 0 0 10px !important;
  }

  &.padding-right {
    padding: 0 10px 0 0 !important;
  }

  &.padding-bottom {
    padding: 0 0 10px 0 !important;
  }
}

.padding-15 {
  padding: 15px !important;

  &.padding-top {
    padding: 15px 0 0 0 !important;
  }

  &.padding-left {
    padding: 0 0 0 15px !important;
  }

  &.padding-right {
    padding: 0 15px 0 0 !important;
  }

  &.padding-bottom {
    padding: 0 0 15px 0 !important;
  }
}

.padding-20 {
  padding: 20px !important;

  &.padding-top {
    padding: 20px 0 0 0 !important;
  }

  &.padding-left {
    padding: 0 0 0 20px !important;
  }

  &.padding-right {
    padding: 0 20px 0 0 !important;
  }

  &.padding-bottom {
    padding: 0 0 20px 0 !important;
  }
}

.padding-25 {
  padding: 25px !important;

  &.padding-top {
    padding: 25px 0 0 0 !important;
  }

  &.padding-left {
    padding: 0 0 0 25px !important;
  }

  &.padding-right {
    padding: 0 25px 0 0 !important;
  }

  &.padding-bottom {
    padding: 0 0 25px 0 !important;
  }
}

.padding-30 {
  padding: 30px !important;

  &.padding-top {
    padding: 30px 0 0 0 !important;
  }

  &.padding-left {
    padding: 0 0 0 30px !important;
  }

  &.padding-right {
    padding: 0 30px 0 0 !important;
  }

  &.padding-bottom {
    padding: 0 0 30px 0 !important;
  }
}

.padding-35 {
  padding: 35px !important;

  &.padding-top {
    padding: 35px 0 0 0 !important;
  }

  &.padding-left {
    padding: 0 0 0 35px !important;
  }

  &.padding-right {
    padding: 0 35px 0 0 !important;
  }

  &.padding-bottom {
    padding: 0 0 35px 0 !important;
  }
}

.padding-40 {
  padding: 40px !important;

  &.padding-top {
    padding: 40px 0 0 0 !important;
  }

  &.padding-left {
    padding: 0 0 0 40px !important;
  }

  &.padding-right {
    padding: 0 40px 0 0 !important;
  }

  &.padding-bottom {
    padding: 0 0 40px 0 !important;
  }
}

.padding-45 {
  padding: 45px !important;

  &.padding-top {
    padding: 45px 0 0 0 !important;
  }

  &.padding-left {
    padding: 0 0 0 45px !important;
  }

  &.padding-right {
    padding: 0 45px 0 0 !important;
  }

  &.padding-bottom {
    padding: 0 0 45px 0 !important;
  }
}

.padding-50 {
  padding: 50px !important;

  &.padding-top {
    padding: 50px 0 0 0 !important;
  }

  &.padding-left {
    padding: 0 0 0 50px !important;
  }

  &.padding-right {
    padding: 0 50px 0 0 !important;
  }

  &.padding-bottom {
    padding: 0 0 50px 0 !important;
  }
}

.padding-55 {
  padding: 55px !important;

  &.padding-top {
    padding: 55px 0 0 0 !important;
  }

  &.padding-left {
    padding: 0 0 0 55px !important;
  }

  &.padding-right {
    padding: 0 55px 0 0 !important;
  }

  &.padding-bottom {
    padding: 0 0 55px 0 !important;
  }
}

.padding-60 {
  padding: 60px !important;

  &.padding-top {
    padding: 65px 0 0 0 !important;
  }

  &.padding-left {
    padding: 0 0 0 65px !important;
  }

  &.padding-right {
    padding: 0 65px 0 0 !important;
  }

  &.padding-bottom {
    padding: 0 0 65px 0 !important;
  }
}

.padding-65 {
  padding: 65px !important;

  &.padding-top {
    padding: 65px 0 0 0 !important;
  }

  &.padding-left {
    padding: 0 0 0 65px !important;
  }

  &.padding-right {
    padding: 0 65px 0 0 !important;
  }

  &.padding-bottom {
    padding: 0 0 65px 0 !important;
  }
}

.padding-70 {
  padding: 70px !important;

  &.padding-top {
    padding: 70px 0 0 0 !important;
  }

  &.padding-left {
    padding: 0 0 0 70px !important;
  }

  &.padding-right {
    padding: 0 70px 0 0 !important;
  }

  &.padding-bottom {
    padding: 0 0 70px 0 !important;
  }
}

.padding-75 {
  padding: 75px !important;

  &.padding-top {
    padding: 75px 0 0 0 !important;
  }

  &.padding-left {
    padding: 0 0 0 75px !important;
  }

  &.padding-right {
    padding: 0 75px 0 0 !important;
  }

  &.padding-bottom {
    padding: 0 0 75px 0 !important;
  }
}

.padding-80 {
  padding: 80px !important;

  &.padding-top {
    padding: 80px 0 0 0 !important;
  }

  &.padding-left {
    padding: 0 0 0 80px !important;
  }

  &.padding-right {
    padding: 0 80px 0 0 !important;
  }

  &.padding-bottom {
    padding: 0 0 80px 0 !important;
  }
}

.padding-85 {
  padding: 85px !important;

  &.padding-top {
    padding: 85px 0 0 0 !important;
  }

  &.padding-left {
    padding: 0 0 0 85px !important;
  }

  &.padding-right {
    padding: 0 85px 0 0 !important;
  }

  &.padding-bottom {
    padding: 0 0 85px 0 !important;
  }
}

.padding-90 {
  padding: 90px !important;

  &.padding-top {
    padding: 90px 0 0 0 !important;
  }

  &.padding-left {
    padding: 0 0 0 90px !important;
  }

  &.padding-right {
    padding: 0 90px 0 0 !important;
  }

  &.padding-bottom {
    padding: 0 0 90px 0 !important;
  }
}

.padding-95 {
  padding: 95px !important;

  &.padding-top {
    padding: 95px 0 0 0 !important;
  }

  &.padding-left {
    padding: 0 0 0 95px !important;
  }

  &.padding-right {
    padding: 0 95px 0 0 !important;
  }

  &.padding-bottom {
    padding: 0 0 95px 0 !important;
  }
}

.padding-100 {
  padding: 100px !important;

  &.padding-top {
    padding: 100px 0 0 0 !important;
  }

  &.padding-left {
    padding: 0 0 0 100px !important;
  }

  &.padding-right {
    padding: 0 100px 0 0 !important;
  }

  &.padding-bottom {
    padding: 0 0 100px 0 !important;
  }
}

/* padding */

/* margin */

.mlr-5 {
  margin: 0 5px !important;
}

.mlr-10 {
  margin: 0 10px !important;
}

.mlr-15 {
  margin: 0 15px !important;
}

.mlr-20 {
  margin: 0 20px !important;
}

.mlr-25 {
  margin: 0 25px !important;
}

.mlr-30 {
  margin: 0 30px !important;
}

.mlr-35 {
  margin: 0 35px !important;
}

.mlr-40 {
  margin: 0 40px !important;
}

.mlr-45 {
  margin: 0 45px !important;
}

.mlr-50 {
  margin: 0 50px !important;
}

.mlr-55 {
  margin: 0 55px !important;
}

.mlr-60 {
  margin: 0 60px !important;
}

.mlr-65 {
  margin: 0 65px !important;
}

.mlr-70 {
  margin: 0 70px !important;
}

.mlr-75 {
  margin: 0 75px !important;
}

.mlr-80 {
  margin: 0 80px !important;
}

.mlr-85 {
  margin: 0 85px !important;
}

.mlr-90 {
  margin: 0 90px !important;
}

.mlr-95 {
  margin: 0 95px !important;
}

.mlr-100 {
  margin: 0 100px !important;
}

.mtb-5 {
  margin: 5px 0 !important;
}

.mtb-10 {
  margin: 10px 0 !important;
}

.mtb-15 {
  margin: 15px 0 !important;
}

.mtb-20 {
  margin: 20px 0 !important;
}

.mtb-25 {
  margin: 25px 0 !important;
}

.mtb-30 {
  margin: 30px 0 !important;
}

.mtb-35 {
  margin: 35px 0 !important;
}

.mtb-40 {
  margin: 40px 0 !important;
}

.mtb-45 {
  margin: 45px 0 !important;
}

.mtb-50 {
  margin: 50px 0 !important;
}

.mtb-55 {
  margin: 55px 0 !important;
}

.mtb-60 {
  margin: 60px 0 !important;
}

.mtb-65 {
  margin: 65px 0 !important;
}

.mtb-70 {
  margin: 70px 0 !important;
}

.mtb-75 {
  margin: 75px 0 !important;
}

.mtb-80 {
  margin: 80px 0 !important;
}

.mtb-85 {
  margin: 85px 0 !important;
}

.mtb-90 {
  margin: 90px 0 !important;
}

.mtb-95 {
  margin: 95px 0 !important;
}

.mtb-100 {
  margin: 100px 0 0 !important;
}

.margin-5 {
  margin: 5px !important;

  &.margin-top {
    margin: 5px 0 0 0 !important;
  }

  &.margin-left {
    margin: 0 0 0 5px !important;
  }

  &.margin-right {
    margin: 0 5px 0 0 !important;
  }

  &.margin-bottom {
    margin: 0 0 5px 0 !important;
  }
}

.margin-10 {
  margin: 10px !important;

  &.margin-top {
    margin: 10px 0 0 0 !important;
  }

  &.margin-left {
    margin: 0 0 0 10px !important;
  }

  &.margin-right {
    margin: 0 10px 0 0 !important;
  }

  &.margin-bottom {
    margin: 0 0 10px 0 !important;
  }
}

.margin-15 {
  margin: 15px !important;

  &.margin-top {
    margin: 15px 0 0 0 !important;
  }

  &.margin-left {
    margin: 0 0 0 15px !important;
  }

  &.margin-right {
    margin: 0 15px 0 0 !important;
  }

  &.margin-bottom {
    margin: 0 0 15px 0 !important;
  }
}

.margin-20 {
  margin: 20px !important;

  &.margin-top {
    margin: 20px 0 0 0 !important;
  }

  &.margin-left {
    margin: 0 0 0 20px !important;
  }

  &.margin-right {
    margin: 0 20px 0 0 !important;
  }

  &.margin-bottom {
    margin: 0 0 20px 0 !important;
  }
}

.margin-25 {
  margin: 25px !important;

  &.margin-top {
    margin: 25px 0 0 0 !important;
  }

  &.margin-left {
    margin: 0 0 0 25px !important;
  }

  &.margin-right {
    margin: 0 25px 0 0 !important;
  }

  &.margin-bottom {
    margin: 0 0 25px 0 !important;
  }
}

.margin-30 {
  margin: 30px !important;

  &.margin-top {
    margin: 30px 0 0 0 !important;
  }

  &.margin-left {
    margin: 0 0 0 30px !important;
  }

  &.margin-right {
    margin: 0 30px 0 0 !important;
  }

  &.margin-bottom {
    margin: 0 0 30px 0 !important;
  }
}

.margin-35 {
  margin: 35px !important;

  &.margin-top {
    margin: 35px 0 0 0 !important;
  }

  &.margin-left {
    margin: 0 0 0 35px !important;
  }

  &.margin-right {
    margin: 0 35px 0 0 !important;
  }

  &.margin-bottom {
    margin: 0 0 35px 0 !important;
  }
}

.margin-40 {
  margin: 40px !important;

  &.margin-top {
    margin: 40px 0 0 0 !important;
  }

  &.margin-left {
    margin: 0 0 0 40px !important;
  }

  &.margin-right {
    margin: 0 40px 0 0 !important;
  }

  &.margin-bottom {
    margin: 0 0 40px 0 !important;
  }
}

.margin-45 {
  margin: 45px !important;

  &.margin-top {
    margin: 45px 0 0 0 !important;
  }

  &.margin-left {
    margin: 0 0 0 45px !important;
  }

  &.margin-right {
    margin: 0 45px 0 0 !important;
  }

  &.margin-bottom {
    margin: 0 0 45px 0 !important;
  }
}

.margin-50 {
  margin: 50px !important;

  &.margin-top {
    margin: 50px 0 0 0 !important;
  }

  &.margin-left {
    margin: 0 0 0 50px !important;
  }

  &.margin-right {
    margin: 0 50px 0 0 !important;
  }

  &.margin-bottom {
    margin: 0 0 50px 0 !important;
  }
}

.margin-55 {
  margin: 55px !important;

  &.margin-top {
    margin: 55px 0 0 0 !important;
  }

  &.margin-left {
    margin: 0 0 0 55px !important;
  }

  &.margin-right {
    margin: 0 55px 0 0 !important;
  }

  &.margin-bottom {
    margin: 0 0 55px 0 !important;
  }
}

.margin-60 {
  margin: 60px !important;

  &.margin-top {
    margin: 65px 0 0 0 !important;
  }

  &.margin-left {
    margin: 0 0 0 65px !important;
  }

  &.margin-right {
    margin: 0 65px 0 0 !important;
  }

  &.margin-bottom {
    margin: 0 0 65px 0 !important;
  }
}

.margin-65 {
  margin: 65px !important;

  &.margin-top {
    margin: 65px 0 0 0 !important;
  }

  &.margin-left {
    margin: 0 0 0 65px !important;
  }

  &.margin-right {
    margin: 0 65px 0 0 !important;
  }

  &.margin-bottom {
    margin: 0 0 65px 0 !important;
  }
}

.margin-70 {
  margin: 70px !important;

  &.margin-top {
    margin: 70px 0 0 0 !important;
  }

  &.margin-left {
    margin: 0 0 0 70px !important;
  }

  &.margin-right {
    margin: 0 70px 0 0 !important;
  }

  &.margin-bottom {
    margin: 0 0 70px 0 !important;
  }
}

.margin-75 {
  margin: 75px !important;

  &.margin-top {
    margin: 75px 0 0 0 !important;
  }

  &.margin-left {
    margin: 0 0 0 75px !important;
  }

  &.margin-right {
    margin: 0 75px 0 0 !important;
  }

  &.margin-bottom {
    margin: 0 0 75px 0 !important;
  }
}

.margin-80 {
  margin: 80px !important;

  &.margin-top {
    margin: 80px 0 0 0 !important;
  }

  &.margin-left {
    margin: 0 0 0 80px !important;
  }

  &.margin-right {
    margin: 0 80px 0 0 !important;
  }

  &.margin-bottom {
    margin: 0 0 80px 0 !important;
  }
}

.margin-85 {
  margin: 85px !important;

  &.margin-top {
    margin: 85px 0 0 0 !important;
  }

  &.margin-left {
    margin: 0 0 0 85px !important;
  }

  &.margin-right {
    margin: 0 85px 0 0 !important;
  }

  &.margin-bottom {
    margin: 0 0 85px 0 !important;
  }
}

.margin-90 {
  margin: 90px !important;

  &.margin-top {
    margin: 90px 0 0 0 !important;
  }

  &.margin-left {
    margin: 0 0 0 90px !important;
  }

  &.margin-right {
    margin: 0 90px 0 0 !important;
  }

  &.margin-bottom {
    margin: 0 0 90px 0 !important;
  }
}

.margin-95 {
  margin: 95px !important;

  &.margin-top {
    margin: 95px 0 0 0 !important;
  }

  &.margin-left {
    margin: 0 0 0 95px !important;
  }

  &.margin-right {
    margin: 0 95px 0 0 !important;
  }

  &.margin-bottom {
    margin: 0 0 95px 0 !important;
  }
}

.margin-100 {
  margin: 100px !important;

  &.margin-top {
    margin: 100px 0 0 0 !important;
  }

  &.margin-left {
    margin: 0 0 0 100px !important;
  }

  &.margin-right {
    margin: 0 100px 0 0 !important;
  }

  &.margin-bottom {
    margin: 0 0 100px 0 !important;
  }
}

/* margin */

/* height */

.h-20 {
  height: 20px !important;
}

.h-25 {
  height: 25px;
}

.h-30 {
  height: 30px !important;
}

.h-35 {
  height: 35px !important;
}

.h-50 {
  height: 50px !important;
}

.h-100 {
  height: 100px !important;
}

.h-150 {
  height: 150px !important;
}

.h-200 {
  height: 200px !important;
}

.h-250 {
  height: 250px !important;
}

.h-300 {
  height: 300px !important;
}

.h-310 {
  height: 310px !important;
}

.h-320 {
  height: 320px !important;
}

.h-330 {
  height: 330px !important;
}

.h-340 {
  height: 340px !important;
}

.h-350 {
  height: 350px !important;
}

.h-360 {
  height: 360px !important;
}

.h-370 {
  height: 370px !important;
}

.h-380 {
  height: 380px !important;
}

.h-390 {
  height: 390px !important;
}

.h-400 {
  height: 400px !important;
}

.h-450 {
  height: 450px !important;
}

.h-500 {
  height: 500px !important;
}

.h-550 {
  height: 550px !important;
}

.h-600 {
  height: 600px !important;
}

.h-650 {
  height: 650px !important;
}

.h-700 {
  height: 700px !important;
}

.h-750 {
  height: 750px !important;
}

.h-800 {
  height: 800px !important;
}

/* height */

/* width */

.w-4 {
  width: 4px !important;
}

.w-50 {
  width: 50px !important;
}

.w-100 {
  width: 100px !important;
}

.w-150 {
  width: 150px !important;
}

.w-200 {
  width: 200px !important;
}

.w-250 {
  width: 250px !important;
}

.w-300 {
  width: 300px !important;
}

.w-350 {
  width: 350px !important;
}

.w-400 {
  width: 400px !important;
}

.w-450 {
  width: 450px !important;
}

.w-500 {
  width: 500px !important;
}

.w-550 {
  width: 550px !important;
}

.w-600 {
  width: 600px !important;
}

.w-650 {
  width: 650px !important;
}

.w-700 {
  width: 700px !important;
}

.w-750 {
  width: 750px !important;
}

.w-800 {
  width: 800px !important;
}

/* width */

/* min height */

.min-h-auto {
  min-height: auto !important;
}

.min-h-50 {
  min-height: 50px !important;
}

.min-h-100 {
  min-height: 100px !important;
}

.min-h-150 {
  min-height: 150px !important;
}

.min-h-200 {
  min-height: 200px !important;
}

.min-h-250 {
  min-height: 250px !important;
}

.min-h-300 {
  min-height: 300px !important;
}

.min-h-350 {
  min-height: 350px !important;
}

.min-h-400 {
  min-height: 400px !important;
}

.min-h-450 {
  min-height: 450px !important;
}

.min-h-500 {
  min-height: 500px !important;
}

.min-h-550 {
  min-height: 550px !important;
}

.min-h-600 {
  min-height: 600px !important;
}

.min-h-650 {
  min-height: 650px !important;
}

.min-h-700 {
  min-height: 700px !important;
}

.min-h-750 {
  min-height: 750px !important;
}

.min-h-800 {
  min-height: 800px !important;
}

/* min height */

/* min width */

.min-w-auto {
  min-width: auto !important;
}

.min-w-0 {
  min-width: 0 !important;
}

.min-w-50 {
  min-width: 50px !important;
}

.min-w-100 {
  min-width: 100px !important;
}

.min-w-150 {
  min-width: 150px !important;
}

.min-w-200 {
  min-width: 200px !important;
}

.min-w-250 {
  min-width: 250px !important;
}

.min-w-300 {
  min-width: 300px !important;
}

.min-w-350 {
  min-width: 350px !important;
}

.min-w-400 {
  min-width: 400px !important;
}

.min-w-450 {
  min-width: 450px !important;
}

.min-w-500 {
  min-width: 500px !important;
}

.min-w-550 {
  min-width: 550px !important;
}

.min-w-600 {
  min-width: 600px !important;
}

.min-w-650 {
  min-width: 650px !important;
}

.min-w-700 {
  min-width: 700px !important;
}

.min-w-750 {
  min-width: 750px !important;
}

.min-w-800 {
  min-width: 800px !important;
}

/* min width */

/* max height */

.max-h-50 {
  max-height: 50px !important;
}

.max-h-100 {
  max-height: 100px !important;
}

.max-h-150 {
  max-height: 150px !important;
}

.max-h-200 {
  max-height: 200px !important;
}

.max-h-250 {
  max-height: 250px !important;
}

.max-h-300 {
  max-height: 300px !important;
}

.max-h-350 {
  max-height: 350px !important;
}

.max-h-400 {
  max-height: 400px !important;
}

.max-h-450 {
  max-height: 450px !important;
}

.max-h-500 {
  max-height: 500px !important;
}

.max-h-550 {
  max-height: 550px !important;
}

.max-h-600 {
  max-height: 600px !important;
}

.max-h-650 {
  max-height: 650px !important;
}

.max-h-700 {
  max-height: 700px !important;
}

.max-h-750 {
  max-height: 750px !important;
}

.max-h-800 {
  max-height: 800px !important;
}

/* max height */

/* max width */

.max-w-50 {
  max-width: 50px !important;
}

.max-w-100 {
  max-width: 100px !important;
}

.max-w-150 {
  max-width: 150px !important;
}

.max-w-200 {
  max-width: 200px !important;
}

.max-w-250 {
  max-width: 250px !important;
}

.max-w-300 {
  max-width: 300px !important;
}

.max-w-350 {
  max-width: 350px !important;
}

.max-w-400 {
  max-width: 400px !important;
}

.max-w-450 {
  max-width: 450px !important;
}

.max-w-500 {
  max-width: 500px !important;
}

.max-w-550 {
  max-width: 550px !important;
}

.max-w-600 {
  max-width: 600px !important;
}

.max-w-650 {
  max-width: 650px !important;
}

.max-w-700 {
  max-width: 700px !important;
}

.max-w-750 {
  max-width: 750px !important;
}

.max-w-800 {
  max-width: 800px !important;
}

/* max width */

/* white-space */

.ws-inherit {
  white-space: inherit !important;
}

.ws-initial {
  white-space: initial !important;
}

.ws-normal {
  white-space: normal !important;
}

.ws-nowrap {
  white-space: nowrap !important;
}

.ws-pre {
  white-space: pre !important;
}

.ws-pre-line {
  white-space: pre-line !important;
}

.ws-pre-wrap {
  white-space: pre-wrap !important;
}

.ws-unset {
  white-space: unset !important;
}

/* white-space */

/* margin */

/* border */

.no-border {
  border: none !important;
}

.b-1 {
  border-width: 1px !important;
}

.b-2 {
  border-width: 2px !important;
}

.b-3 {
  border-width: 3px !important;
}

.b-4 {
  border-width: 4px !important;
}

.b-5 {
  border-width: 5px !important;
}

.b-6 {
  border-width: 6px !important;
}

.b-7 {
  border-width: 7px !important;
}

.b-8 {
  border-width: 8px !important;
}

.b-9 {
  border-width: 9px !important;
}

.b-10 {
  border-width: 10px !important;
}

.bl-1 {
  border-width: 0 !important;
  border-left-width: 1px !important;
}

.bl-2 {
  border-width: 0 !important;
  border-left-width: 2px !important;
}

.bl-3 {
  border-width: 0 !important;
  border-left-width: 3px !important;
}

.bl-4 {
  border-width: 0 !important;
  border-left-width: 4px !important;
}

.bl-5 {
  border-width: 0 !important;
  border-left-width: 5px !important;
}

.bl-6 {
  border-width: 0 !important;
  border-left-width: 6px !important;
}

.bl-7 {
  border-width: 0 !important;
  border-left-width: 7px !important;
}

.bl-8 {
  border-width: 0 !important;
  border-left-width: 8px !important;
}

.bl-9 {
  border-width: 0 !important;
  border-left-width: 9px !important;
}

.bl-10 {
  border-width: 0 !important;
  border-left-width: 10px !important;
}

.br-1 {
  border-width: 0 !important;
  border-right-width: 1px !important;
}

.br-2 {
  border-width: 0 !important;
  border-right-width: 2px !important;
}

.br-3 {
  border-width: 0 !important;
  border-right-width: 3px !important;
}

.br-4 {
  border-width: 0 !important;
  border-right-width: 4px !important;
}

.br-5 {
  border-width: 0 !important;
  border-right-width: 5px !important;
}

.br-6 {
  border-width: 0 !important;
  border-right-width: 6px !important;
}

.br-7 {
  border-width: 0 !important;
  border-right-width: 7px !important;
}

.br-8 {
  border-width: 0 !important;
  border-right-width: 8px !important;
}

.br-9 {
  border-width: 0 !important;
  border-right-width: 9px !important;
}

.br-10 {
  border-width: 0 !important;
  border-right-width: 10px !important;
}

.bt-1 {
  border-width: 0 !important;
  border-top-width: 1px !important;
}

.bt-2 {
  border-width: 0 !important;
  border-top-width: 2px !important;
}

.bt-3 {
  border-width: 0 !important;
  border-top-width: 3px !important;
}

.bt-4 {
  border-width: 0 !important;
  border-top-width: 4px !important;
}

.bt-5 {
  border-width: 0 !important;
  border-top-width: 5px !important;
}

.bt-6 {
  border-width: 0 !important;
  border-top-width: 6px !important;
}

.bt-7 {
  border-width: 0 !important;
  border-top-width: 7px !important;
}

.bt-8 {
  border-width: 0 !important;
  border-top-width: 8px !important;
}

.bt-9 {
  border-width: 0 !important;
  border-top-width: 9px !important;
}

.bt-10 {
  border-width: 0 !important;
  border-top-width: 10px !important;
}

.bb-1 {
  border-width: 0 !important;
  border-bottom-width: 1px !important;
}

.bb-2 {
  border-width: 0 !important;
  border-bottom-width: 2px !important;
}

.bb-3 {
  border-width: 0 !important;
  border-bottom-width: 3px !important;
}

.bb-4 {
  border-width: 0 !important;
  border-bottom-width: 4px !important;
}

.bb-5 {
  border-width: 0 !important;
  border-bottom-width: 5px !important;
}

.bb-6 {
  border-width: 0 !important;
  border-bottom-width: 6px !important;
}

.bb-7 {
  border-width: 0 !important;
  border-bottom-width: 7px !important;
}

.bb-8 {
  border-width: 0 !important;
  border-bottom-width: 8px !important;
}

.bb-9 {
  border-width: 0 !important;
  border-bottom-width: 9px !important;
}

.bb-10 {
  border-width: 0 !important;
  border-bottom-width: 10px !important;
}

.blr-1 {
  border-width: 0 !important;
  border-left-width: 1px !important;
  border-right-width: 1px !important;
}

.blr-2 {
  border-width: 0 !important;
  border-left-width: 2px !important;
  border-right-width: 2px !important;
}

.blr-3 {
  border-width: 0 !important;
  border-left-width: 3px !important;
  border-right-width: 3px !important;
}

.blr-4 {
  border-width: 0 !important;
  border-left-width: 4px !important;
  border-right-width: 4px !important;
}

.blr-5 {
  border-width: 0 !important;
  border-left-width: 5px !important;
  border-right-width: 5px !important;
}

.blr-6 {
  border-width: 0 !important;
  border-left-width: 6px !important;
  border-right-width: 6px !important;
}

.blr-7 {
  border-width: 0 !important;
  border-left-width: 7px !important;
  border-right-width: 7px !important;
}

.blr-8 {
  border-width: 0 !important;
  border-left-width: 8px !important;
  border-right-width: 8px !important;
}

.blr-9 {
  border-width: 0 !important;
  border-left-width: 9px !important;
  border-right-width: 9px !important;
}

.blr-10 {
  border-width: 0 !important;
  border-left-width: 10px !important;
  border-right-width: 10px !important;
}

.btb-1 {
  border-width: 0 !important;
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
}

.btb-2 {
  border-width: 0 !important;
  border-top-width: 2px !important;
  border-bottom-width: 2px !important;
}

.btb-3 {
  border-width: 0 !important;
  border-top-width: 3px !important;
  border-bottom-width: 3px !important;
}

.btb-4 {
  border-width: 0 !important;
  border-top-width: 4px !important;
  border-bottom-width: 4px !important;
}

.btb-5 {
  border-width: 0 !important;
  border-top-width: 5px !important;
  border-bottom-width: 5px !important;
}

.btb-6 {
  border-width: 0 !important;
  border-top-width: 6px !important;
  border-bottom-width: 6px !important;
}

.btb-7 {
  border-width: 0 !important;
  border-top-width: 7px !important;
  border-bottom-width: 7px !important;
}

.btb-8 {
  border-width: 0 !important;
  border-top-width: 8px !important;
  border-bottom-width: 8px !important;
}

.btb-9 {
  border-width: 0 !important;
  border-top-width: 9px !important;
  border-bottom-width: 9px !important;
}

.btb-10 {
  border-width: 0 !important;
  border-top-width: 10px !important;
  border-bottom-width: 10px !important;
}

.b-dotted {
  border-style: dotted !important;
}

.b-dashed {
  border-style: dashed !important;
}

.b-solid {
  border-style: solid !important;
}

.b-double {
  border-style: double !important;
}

.b-groove {
  border-style: groove !important;
}

.b-ridge {
  border-style: ridge !important;
}

.b-inset {
  border-style: inset !important;
}

.b-outset {
  border-style: outset !important;
}

.b-none {
  border-style: none !important;
}

.b-hidden {
  border-style: hidden !important;
}

.b-mix {
  border-style: dotted dashed solid double !important;
}

/* border */

/* outline style */

.outline-dotted {
  outline-style: dotted;
}

.outline-dashed {
  outline-style: dashed;
}

.outline-solid {
  outline-style: solid;
}

.outline-double {
  outline-style: double;
}

.outline-groove {
  outline-style: groove;
}

.outline-ridge {
  outline-style: ridge;
}

.outline-inset {
  outline-style: inset;
}

.outline-outset {
  outline-style: outset;
}

/* outline style */

/* h and small */

h1 small,
h1 .small,
h2 small,
h2 .small,
h3 small,
h3 .small,
h4 small,
h4 .small,
h5 small,
h5 .small,
h6 small,
h6 .small,
.h1 small,
.h1 .small,
.h2 small,
.h2 .small,
.h3 small,
.h3 .small,
.h4 small,
.h4 .small,
.h5 small,
.h5 .small,
.h6 small,
.h6 .small {
  font-weight: 400;
  font-size: 75%;
  color: #798892;
}

/* text align */

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-left-exact {
  text-align: left !important;
}

/* text align */

/* border */

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}
/* border */

/* float */
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}
/* float */

/* direction */
.direction-right {
  direction: rtl !important;
}
.direction-left {
  direction: ltr !important;
}
/* direction */

/* overflow */
.s-overflow-x {
  overflow-x: scroll !important;
}
.s-overflow-y {
  overflow-y: scroll !important;
}
.s-overflow {
  overflow: scroll !important;
}
.a-overflow-x {
  overflow-x: auto !important;
}
.a-overflow-y {
  overflow-y: auto !important;
}
.a-overflow {
  overflow: auto !important;
}
.h-overflow-x {
  overflow-x: hidden !important;
}
.h-overflow-y {
  overflow-y: hidden !important;
}
.h-overflow {
  overflow: hidden !important;
}

.unset-overflow {
  overflow: unset !important;
}

/* overflow */
/* text align */

/* cursor */

.c-pointer {
  cursor: pointer !important;
}
.c-none {
  cursor: none !important;
}
.c-initial {
  cursor: initial !important;
}

/* cursor */

/* float */

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

/* float */

.width-5rem {
  width: 5rem !important;
}

.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

.flex-center {
  display: flex;

  &.horizontal {
    justify-content: center;
  }

  &.vertical {
    align-items: center;
  }

  &.both {
    align-items: center;
    justify-content: center;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.space-between {
    justify-content: space-between;
  }
}

.zoom-09 {
  zoom: 0.9 !important;
}

.width-2rem {
  width: 2rem !important;
}

.width-3rem {
  width: 3rem;
}
.width-6rem {
  width: 6rem;
}

.width-8rem {
  width: 8rem;
}

.width-12rem {
  width: 12rem;
}

.h-100vh {
  height: 100vh !important;
}

.box-shadow-50 {
  box-shadow: 5px 0px 28px 10px rgba(0, 0, 0, 0.5) !important;
}

.width-divide-7 {
  width: calc(100% / 7.5) !important;
}

.b-radius-5 {
  border-radius: 5px;
}

.l-height-2 {
  line-height: 2 !important;
}

.flex-1 {
  flex-grow: 1;
}

.flex-2 {
  flex-grow: 2;
}

.flex-3 {
  flex-grow: 3;
}

.flex-4 {
  flex-grow: 4;
}

.flex-5 {
  flex-grow: 5;
}

.flex-6 {
  flex-grow: 6;
}

.flex-7 {
  flex-grow: 7;
}

.flex-8 {
  flex-grow: 8;
}

.flex-9 {
  flex-grow: 9;
}

.flex-10 {
  flex-grow: 10;
}

.flex-11 {
  flex-grow: 11;
}

.flex-12 {
  flex-grow: 12;
}

.top-10{
  top: 10px;
}

.right-10{
  right: 10px;
}

.left-10{
  left: 10px;
}

.bottom-10{
  bottom: 10px;
}